import PropTypes from 'prop-types'

import './NotFoundPage.css'
import { wrap } from '../../layouts/layouts'
import CenteredBlock from '../../components/SimpleComponents/CenteredBlock'

export const NotFoundPage = (props) => {
    return wrap("default", props, (
        <CenteredBlock>
            <div className="text"><center>Page not found</center></div>
        </CenteredBlock>
    ))
}

NotFoundPage.propTypes = {
    // Page title for the browser tab
    title:  PropTypes.string.isRequired,
}


export default NotFoundPage