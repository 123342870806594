import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authAPI } from "../apis/authAPI";
import SignInModal from "../components/Modals/SignInModal/SignInModal";

export const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [connected, setConnected] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);
    const [isSigninOpen, setSigninOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Connection and user state
        authAPI.connect().then((resp) => {
            //console.log(resp);
            if (resp) {
                setAuthenticated(resp.authenticated);
                setConnected(resp.connected);
                setUser(resp.user);
            }
        })
    }, [])

    const signin = (new_user, callback) => {
        setSigninOpen(true);
        // setUser(new_user);
        // if (typeof callback === "function")
        //     callback();
    }

    const signout = (callback) => {
        authAPI.logout().then(() => {
            authAPI.connect().then((resp) => {
                if (resp) {
                    //console.log(resp);
                    setAuthenticated(resp.authenticated);
                    setConnected(resp.connected);
                    setUser(null);
                    navigate("/");
                }
            })
        })
        // dal.logout(()=>{
        //     setUser(null);
        //     navigate("/");
        //     if (typeof callback === "function")
        //         callback();
        // })
    }

    const handleRequestClose = () => {
        setSigninOpen(false);
    }

    const value = {
        connected,
        authenticated,
        user,
        signin,
        signout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
            <SignInModal
                isOpen={isSigninOpen}
                onRequestClose={handleRequestClose}
            />
        </AuthContext.Provider>
    )
}