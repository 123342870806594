import { api } from './configs/axiosConfigs';
import { prepareErr, defineCancelAPIObject } from './configs/axiosUtils';
import { notifier } from '../libs/notifier';



export const mtgAPI = {
    getSets: async function(cancel = false) {
        const url = "/sets";
        const response = await api.post(url, {}, {
            signal: cancel ? cancelApiObject[this.getSets.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return response.data.sets;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return {};
        }
    },

    getContent: async function(alias, cancel = false) {
        const url = "/content";
        const formData = new FormData();
        formData.append("alias", alias);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.getContent.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return response.data.content;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return "";
        }
    },

    getParams: async function(cancel = false) {
        const url = "/params";
        const response = await api.post(url, {}, {
            signal: cancel ? cancelApiObject[this.getParams.name].handleRequestCancellation().signal : undefined,
        })

        if (response?.data?.status === "success") {
            return response.data.params;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return "";
        }
    }
}

// Show formatted API error
const showAPIErr = (url, message) => {
    let errMsg = "Error in storageAPI call " + url +  " : " + message;
    notifier.logToConsole(notifier.level.error, errMsg, true);
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelAPIObject(mtgAPI);