import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'

import './DecksPage.css'

//import { dal } from '../../libs/dal'
import { wrap } from '../../layouts/layouts'
import DeckListPanel from './DeckListPanel/DeckListPanel'
import DeckConstructorPanel from './DeckConstructorPanel/DeckConstructorPanel'
import { useDeck } from '../../hook/useDeck';


/**
 * Deck construction page
 * @content
 */
const DecksPage = (props) => {
    const navigate = useNavigate()
    const {activeDeck} = useDeck();

    useEffect(() => {
        console.log('Initialization: DecksPage');
    }, [])

    return wrap(props.layout, props, (
        <div className="decks">
            <DeckListPanel
                onSelectDeck={handleSelectDeck}
            />
            {activeDeck && (
            <DeckConstructorPanel />
            )}
        </div>
    ))

    function handleSelectDeck(deck) {
        navigate(getCurrentURL(deck));
    }

    /*handleActivateStorage = (storage, callback) => {
        if (storage === null) {
            this.setState({
                active_storage: null
            }, () => {
                //this.props.navigate(this.getCurrentURL())
            })
        } else {
            this.setState({
                active_storage: storage,
                active_sample: null,
                //show_more_actions: false,
            }, () => {
                //this.props.navigate(this.getCurrentURL())
                dal.getSamples(storage.id, () => {
                    //console.log('samples!')
                    if (callback) callback()
                })
            })
        }
    }*/

    // applyDecks = () => {
    //     let new_decks = {}
    //     for (let key in dal.decks) {
    //         let deck = Object.assign({}, dal.decks[key])
    //         new_decks[key] = deck
    //     }
    //     let active_deck_upd = null
    //     if (this.props.match && this.props.match.params.deck_id && this.state.active_deck ===  null) {
    //         let deck_id = this.props.match.params.deck_id
    //         if (deck_id in new_decks)
    //             active_deck_upd = Object.assign({}, new_decks[deck_id])
    //             // Initiate loading decks samples
    //             this.handleSelectDeck(active_deck_upd)
    //     }

    //     // Update active deck
    //     if (this.state.active_deck && this.state.active_deck.id in new_decks) {
    //         active_deck_upd = Object.assign({}, new_decks[this.state.active_deck.id])
    //     }
    //     // Apply changes
    //     this.setState({
    //         active_deck: active_deck_upd,
    //         decks: new_decks,
    //     })
    // }

    // applyStorages = () => {
    //     this.setState({
    //         storages: dal.storages,
    //     }, () =>{
    //         if (this.state.active_storage === null && Object.keys(this.state.storages).length) {
    //             let first_key = Object.keys(this.state.storages)[0]
    //             let active_storage_upd = this.state.storages[first_key]
    //             console.log('active_storage_upd', active_storage_upd)
    //             this.handleActivateStorage(active_storage_upd)
    //         }
    //     })
    // }

    // Apply samples from DAL
    /*applySamples = () => {
        console.log(this)
        // Cancel applying if no storage selected
        if (!this.state.active_storage)
            return
        // Prepare samples
        let new_samples = {}
        for (let key in dal.samples) {
            let sample = Object.assign({}, dal.samples[key])
            if (sample.storage_id === this.state.active_storage.id)
                new_samples[key] = sample
        }
        console.log(new_samples)
        // Remove samples from another storage (after move sample)
        // for (let key in new_samples) {
        //     if (new_samples[key].storage_id !== this.state.active_storage.id)
        //         delete new_samples[key]
        // }

        // Update active sample
        // let upd_active_sample = null
        // if (this.state.active_sample && this.state.active_sample.id in new_samples) {
        //     upd_active_sample = Object.assign({}, new_samples[this.state.active_sample.id])
        // }
        // Apply changes
        this.setState({
            samples: new_samples,
            //active_sample: upd_active_sample,
        })
    }*/

    // Get URL for current page
    function getCurrentURL(deck) {
        let url = "/decks";
        if (deck) {
            url = "/decks/" + deck.id;
        }
        return url;
    }
}

DecksPage.propTypes = {
    // Page title for the browser tab
    title:          PropTypes.string.isRequired,
    // Page Layout
    layout:         PropTypes.string.isRequired,
}

export default DecksPage

// TODO: Refactor to functional component
// export const WrappedDecksPage = props => {
//     const params = useParams();
//     const navigate = useNavigate();
//     return (
//         <DecksPage
//             {...props}
//             params={params}
//             navigate={navigate}
//         />
//     )
// }