import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './ActiveSamplePanel.css'

import WorkspacePanel, {WorkspacePanelHead} from '../../../components/WorkspacePanel/WorkspacePanel'
import CardInfo from '../../../components/CardInfo/CardInfo'
import SampleInfo from '../../../components/SampleInfo/SampleInfo'
import EditSampleBlock from '../../../components/EditSampleBlock/EditSampleBlock'
import { useAuth } from '../../../hook/useAuth'
import { useSample } from '../../../hook/useSample'



/**
 * Sample information panel
 * @component
 */
const ActiveSamplePanel = (props) => {
    const [editing, setEditing] = useState(false);
    const {user} = useAuth();
    const {updateSample} = useSample();

    useEffect(()=>{
        setEditing(false)
    }, [props.sample])

    const is_owner = (props.sample.owner_id === user?.id)

    return (
        <WorkspacePanel className="active-sample">
            <WorkspacePanelHead title={props.sample.card.name}>
                <div className="spacer"></div>
                <div className="mtgbutton button-close"
                    title="Close sample  information"
                    onClick={()=>{props.onSelect(null)}}
                >Close</div>
            </WorkspacePanelHead>
            <CardInfo card={props.sample.card}>
                {(editing && (
                <div className="edit-sample-wrapper">
                    <EditSampleBlock
                        card={props.sample.card}
                        sample={props.sample}
                        language_default="en"
                        onConfirm={handleUpdateSample}
                        onCancel={handleCancelEditSample}
                    />
                </div>
                )) || (
                    <SampleInfo
                        sample={props.sample}
                        is_owned={is_owner}
                        onStartEdit={handleStartEdit}
                        onDelete={handleDeselect}
                        onMove={handleDeselect}
                    />
                )}
            </CardInfo>
        </WorkspacePanel>
    )

    function handleDeselect() {
        props.onSelect(null);
    }
    function handleStartEdit() {
        setEditing(true);
    }
    function handleCancelEditSample() {
        setEditing(false);
    }

    function handleUpdateSample(qty, condition, finish, note, language) {
        updateSample(props.sample.id, props.sample.storage_id, qty, condition, finish, note, language, (sample) => {
            setEditing(false);
            props.onUpdate(sample);
        })
    }
}

ActiveSamplePanel.propTypes = {
    // Sample object
    sample:         PropTypes.object.isRequired,
    // Callback function on sample select
    onSelect:       PropTypes.func.isRequired,
    // Callback function on sample update
    onUpdate:       PropTypes.func.isRequired,
}

export default ActiveSamplePanel