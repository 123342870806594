import React, { useEffect } from 'react'
import { BigNumber } from 'bignumber.js'
import PropTypes from 'prop-types'

import './StorageListPanel.css'

import { useAuth } from '../../../hook/useAuth'
import { useStorage } from '../../../hook/useStorage'
import { storageFilters, storageOrders } from '../../../hoc/StorageProvider'
import { PropTypesCustom } from '../../../libs/helper'
import WorkspacePanel, {WorkspacePanelHead} from '../../../components/WorkspacePanel/WorkspacePanel'
import { SimpleSelectLabeled } from '../../../components/SimpleComponents/SimpleSelect'
import DropDownMenu, { dropDownTemplate } from '../../../components/DropDownMenu/DropDownMenu'
import MenuItem from '../../../components/DropDownMenu/MenuItem/MenuItem'
import Spinner from '../../../components/SimpleComponents/Spinner'



/**
 * Storage List Component. Allows add, modify, delete storages
 * @component
 */
const StorageListPanel = (props) => {
    const {authenticated, signin} = useAuth();

    const {
        storageFilter,
        setStorageFilter,
        storageOrder,
        setStorageOrder,
    } = useStorage();

    useEffect(()=>{
        console.log("Initialization: StorageListPanel");
    }, [])

    const getTotalPrice = () => {
        let total_price = BigNumber(0);
        for (let key in props.storages) {
            let storage = props.storages[key]
            if (!storage.hidden)
                total_price = total_price.plus(storage.total_price_usd)
        }
        return total_price.toString()
    }

    const filterStorages = (id) => {
        switch (storageFilter) {
            case storageFilters.all:
                return 1
            case storageFilters.visible:
                return props.storages[id].hidden ? 0 : 1
            case storageFilters.public:
                return props.storages[id].public ? 1 : 0
            default:
                return 1
        }
    }

    const sortStorages = (id1, id2) => {
        let s1 = props.storages[id1]
        let s2 = props.storages[id2]
        switch (storageOrder) {
            case storageOrders.shortname:
                if (s1.shortname > s2.shortname)
                    return 1
                else if (s1.shortname < s2.shortname)
                    return -1
                // if shortnames are similar the compare names
                // eslint-disable-line no-fallthrough
            case storageOrders.name:
                if (s1.name > s2.name)
                    return 1
                else if (s1.name < s2.name)
                    return -1
                return 0
            case storageOrders.value:
                return s2.total_price_usd - s1.total_price_usd
            default:
                return s2.id - s1.id
        }
    }

    const getStorageRecordClass = (storage) => {
        let cls = "storage"
        if (props.activeStorage && storage.id === props.activeStorage.id)
            cls += " active"
        if (storage.hidden)
            cls += " hidden"
        return cls
    }

    return(
        <WorkspacePanel className="storage-list">
            <WorkspacePanelHead title="Storages">
                <div className="spacer"></div>
                <div className="mtgbutton button-add" onClick={props.onAddStorageReq}>Add New</div>
            </WorkspacePanelHead>

            <div className="subhead">
                <div className="total"><span className="label">Total value:&nbsp;</span><span className="usd">$&#8202;</span><span className="value">{getTotalPrice()}</span></div>
                <div className="spacer"></div>
                <SimpleSelectLabeled
                    value={storageFilter}
                    className="filtering"
                    onChange={handleFilterChange}
                    label="Show:"
                >
                    <option value={storageFilters.all}>{storageFilters.all}</option>
                    <option value={storageFilters.visible}>{storageFilters.visible}</option>
                    <option value={storageFilters.public}>{storageFilters.public}</option>
                </SimpleSelectLabeled>
                <SimpleSelectLabeled
                    value={storageOrder}
                    className="sorting"
                    onChange={handleSortChange}
                    label="Sort:"
                >
                    <option value={storageOrders.name}>{storageOrders.name}</option>
                    <option value={storageOrders.shortname}>{storageOrders.shortname}</option>
                    <option value={storageOrders.value}>{storageOrders.value}</option>
                </SimpleSelectLabeled>
            </div>
            <div className="content scrollable">
                {(!authenticated) && (
                    <div className="signin-proposal"><span className="link" onClick={()=>{signin()}} href="#">Sign in</span> to manage your collection</div>
                )}
                {(props.loading &&
                    <div className="storages-spinner">
                        <Spinner />
                    </div>
                ) || (Object.keys(props.storages).length &&
                    Object.keys(props.storages)
                        .filter((id) => filterStorages(id))
                        .sort((id1, id2) => sortStorages(id1, id2))
                        //.sort((k1, k2)=>{return this.props.storages[k1].name > this.props.storages[k2].name ? 1 : -1})
                        .map((key, index)=>(
                            <div
                                className={getStorageRecordClass(props.storages[key])}
                                key={props.storages[key].id}
                                onClick={()=>{props.onActivateStorage(props.storages[key])}}
                            >
                                <div className="shortname">{props.storages[key].shortname}</div>
                                <div className="name">{props.storages[key].name}</div>
                                {props.storages[key].public > 0 && (
                                <div className="public" title="This storage is visible for everyone"></div>
                                )}
                                <div className="price"><span className="usd">$&#8202;</span>{props.storages[key].total_price_usd}</div>
                                <DropDownMenu
                                    className="storage-context"
                                    template={dropDownTemplate.small_left}
                                >
                                    <MenuItem text="Modify" onClick={()=>{props.onModifyStorageReq(props.storages[key])}}></MenuItem>
                                    <MenuItem text="Move" onClick={()=>{props.onMoveSamplesReq(props.storages[key])}}></MenuItem>
                                    <MenuItem text="Export" onClick={()=>{props.onExportStorageReq(props.storages[key])}}></MenuItem>
                                    <MenuItem text="Delete" onClick={()=>{props.onDeleteStorageReq(props.storages[key])}}></MenuItem>
                                </DropDownMenu>
                            </div>
                        ))
                ) || (
                    <div className="no-storages">Storages haven't been added yet</div>
                )
                }
            </div>
        </WorkspacePanel>
    )

    function handleSortChange(event) {
        setStorageOrder(event.target.value);
    }
    function handleFilterChange(event) {
        setStorageFilter(event.target.value);
    }
}

StorageListPanel.propTypes = {
    // Associative array of storage objects
    storages:           PropTypes.objectOf(PropTypes.object).isRequired,
    // Active storage object
    activeStorage:      PropTypesCustom.object_or_null_required,
    // Loading storages
    loading:            PropTypes.bool,
    // Callback function on storage select
    onActivateStorage:  PropTypes.func.isRequired,
    // Callback function on add storage request
    onAddStorageReq:    PropTypes.func.isRequired,
    // Callback function on storage modification request
    onModifyStorageReq: PropTypes.func.isRequired,
    // Callback function on samples move request
    onMoveSamplesReq:   PropTypes.func.isRequired,
    // Callback function on delete storage request
    onDeleteStorageReq: PropTypes.func.isRequired,
    // Callback function on storage export request
    onExportStorageReq: PropTypes.func.isRequired,
}

export default StorageListPanel