import React from 'react'
import PropTypes from 'prop-types'

import './SimpleInput.css'


/**
 * Simple input component
 * @component
 */
export const SimpleInput = (props) => {
    const cls = "simple-input" +
                (props.limit ? " limited" : "") +
                (props.className ? " " + props.className: "");
    return(
        <div className={cls}>
            <input
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                title={props.title}
                onChange={props.onChange}
                onKeyDown={props.onKeyDown}
                tabIndex={props.tabIndex}
                //ref={props.inputRef}
                autoFocus={props.autoFocus}
            />
            {props.limit > 0 && (
                <label className="limit" title="Max length">{props.value.length}&thinsp;/&thinsp;{props.limit}</label>
            )}
        </div>
    )
}

SimpleInput.propTypes = {
    // Input type
    type:           PropTypes.string.isRequired,
    // Placeholder for unfocused & empty input
    placeholder:    PropTypes.string,
    // Value of input
    value:      PropTypes.string.isRequired,
    // Title html attribute for component
    title:      PropTypes.string,
    // Custom class
    className:  PropTypes.string,
    // Callback function on change
    onChange:   PropTypes.func,
    // Callback function key down
    onKeyDown:  PropTypes.func,
    // Custom tab order of an element
    tabIndex:   PropTypes.number,
    // Reference
    // inputRef:        PropTypes.oneOfType([
    //     PropTypes.func,
    //     PropTypes.shape({ current: PropTypes.any })
    // ])
    // Field length limit
    limit:      PropTypes.number,
    // Auto focus flag
    autoFocus:  PropTypes.bool,
}

export default SimpleInput