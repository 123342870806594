/**
 * Аварийно завершить скрипт в случае false в качестве условия
 * @param {Boolean} condition Условие для проверки
 * @param {String} message Сообщение о причине остановки
 */
export var assert = function(condition, message) {
    if (!condition) {
        message = 'Assertion failed: ' + message || "Assertion failed! See stack trace for details"
        if (typeof Error !== "undefined") {
            throw new Error(message)
        }
        throw message
    }
}

/**
 * Получить строку с перечислением всех идентификаторов объектов из предоставленного списка
 * @param {Array} objects Массив с объектами, содержащими поле id
 */
export var getIdsString = function(objects) {
    let ids = '' // Формируем список ID исходных фрагментов
    for (let i = 0; i < objects.length; i++)
        ids += objects[i].id + ', '
    return ids.slice(0, -2) // Удаляем последнюю запятую и пробле
}


/**
 * Проверить, является ли строка числом
 * @param {String} string Проверяемая строка
 */
export var isNumber = function(string) {
    if (string.length !== 0 && !Number.isNaN(Number(string)))
        return true
    else
        return false
}

/**
 * Проверить, является ли строка целым числом
 * @param {String} string Проверяемая строка
 */
export var isInteger = function(string) {
    if (string.length !== 0 && !Number.isNaN(Number(string))) {
        let number = Number(string)
        if(Number.isInteger(number))
            return true
        return false
    }
    return false
}

/**
 * Очистить строку от HTML-тегов
 * @param {String} html_string Строка с html-кодом
 */
export var stripHtml = function(html_string){
    return html_string.replace(/<[^>]+>/g, '');
}

/**
 * Возвращает представление даты в формате YYYY-MM-DD HH:MM:SS (UTC+Z)
 * @param {Date} date Дата
 */
export var formatDate = function(date){
    let _pad = (num, size) => {
        var s = String(num);
        while (s.length < (size || 2)) {s = "0" + s;}
        return s;
    }
    let result = _pad(date.getFullYear()) + '-' + _pad(date.getMonth()) + '-' + _pad(date.getDate()) + ' '
               + _pad(date.getHours()) + ':' + _pad(date.getMinutes()) + ':' + _pad(date.getSeconds()) + ' (UTC'+(date.getTimezoneOffset()/60)+')'
    return result
}


/**
 * Checks whether the property with the specified name is of type 'object' or null
 */
const _object_or_null_check = (props, propName, componentName) => {
    const propValue = props[propName]
    if (propValue === null) return
    if (typeof propValue === 'object') return
    return new Error("The prop `"+propName+"` of `"+componentName+"` only accepts object or null")
}

/**
 * Custom PropType's for checking component property types
 */
export const PropTypesCustom = {
    object_or_null: _object_or_null_check,
    object_or_null_required: (props, propName, componentName) => {
        if (!(propName in props))
            return new Error("The prop `"+propName+"` is marked as required in `"+componentName+"`, but its value is `undefined`")
        return _object_or_null_check(props, propName, componentName)
    }
}

/**
 * Download as file
 * @param {String} filename Filename that will be suggested
 * @param {String} content File content
 */
 export var download = function(filename, content) {
    let element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content))
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}

/**
 * Prepare price block from database value
 * @param {String} price    Card price from database
 * @returns {String}        Processed price
 */
export var parsePrice = function(price) {
    if (price === "0.00")
        return (
            <span className="price">?</span>
        )
    return (
        <span className="price">
            <span className="usd">$</span>&thinsp;
            <span className="value">{price}</span>
        </span>
    )
}