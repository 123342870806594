import React from 'react'

import './SimpleLoadingSpinner.css'

/**
 * This simple input component
 * @component
 */
export const SimpleLoadingSpinner = () => {
    return(
        <div className="simple-loading-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default SimpleLoadingSpinner