import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Droppable } from "react-beautiful-dnd";

import './SamplesListContent.css'

import { useMtg } from '../../hook/useMtg';
import { useSample } from '../../hook/useSample';
import { ItemTypes } from '../../utils/items';
import { orderType } from '../SimpleComponents/OrderingSwitch'
import SampleRow from './SampleRow/SampleRow'
import SamplePackRow from './SamplePackRow/SamplePackRow'
import SampleTileDraggable from './SampleTile/SampleTileDraggable'
import { cardFinish } from '../../hoc/MtgProvider';

export const sortType = {
    price: 'price',
    rarity: 'rarity',
    dateChanged: 'date_changed',
    collectorNumber: 'collector_number',
    storage: 'storage',
    name: 'name',
}

const rarityValue = {
    'common': 0,
    'uncommon': 1,
    'rare': 2,
    'mythic': 3
}



/**
 * Content for SamplesList
 * @component
 */
const SamplesListContent = (props) => {
    const {activeSample, updatedSample} = useSample();
    const {cutoffPrice} = useMtg();

    const [packs, setPacks] = useState(() => {
        if (props.show_as_packs && Object.keys(props.samples).length) {
            return getPacks(props.samples);
        }
        return {};
    });
    const [packs_unfold, setPacksUnfold] = useState([]);

    useEffect(() => {
        console.log('Initialization: SamplesListContent');
    }, [])

    useEffect(() => {
        //console.log('updatePacks')
        if (props.show_as_packs)
            setPacks(getPacks(props.samples));
        else
            setPacks({});
    }, [props.samples, props.show_as_packs])

    const sortSamples = (id1, id2) => {
        const s1 = props?.samples[id1];
        const s2 = props?.samples[id2];
        if (!s1 || !s2)
            return !s1 ? 1 : -1;

        let result = 0;
        switch (props.sorting) {
            case sortType.price:
                const p1 = s1.finish === cardFinish.nonfoil ? s1.card.price_usd :
                          (s1.finish === cardFinish.foil ? s1.card.price_usd_foil :
                          (s1.finish === cardFinish.etched ? s1.card.price_usd_etched : 0));
                const p2 = s2.finish === cardFinish.nonfoil ? s2.card.price_usd :
                          (s2.finish === cardFinish.foil ? s2.card.price_usd_foil :
                          (s2.finish === cardFinish.etched ? s2.card.price_usd_etched : 0));
                result = p1 - p2;
                break;
            case sortType.rarity:
                const r1 = s1.card.rarity in rarityValue ? rarityValue[s1.card.rarity] : 0;
                const r2 = s2.card.rarity in rarityValue ? rarityValue[s2.card.rarity] : 0;
                result = r1 - r2;
                break;
            case sortType.dateChanged:
                result = Date.parse(s1.date_changed) - Date.parse(s2.date_changed);
                break;
            case sortType.storage:
                result = s2.storage_id - s1.storage_id;
                break;
            case sortType.name:
                result = s1.card.name > s2.card.name ? 1 : -1;
                break;
            case sortType.collectorNumber:
            default:
                if (s1.card.set_id !== s2.card.set_id)
                    result = s2.card.set_id - s1.card.set_id;
                else
                    result = (parseInt(s1.card.c_number) > parseInt(s2.card.c_number) ? 1 : -1);
        }
        return (props.ordering === orderType.ascending ? result : -result);
    }

    const sortPacks = (id1, id2) => {
        const p1 = packs[id1];
        const p2 = packs[id2];
        const s1 = p1.samples[0];
        const s2 = p2.samples[0];
        let result = 0;
        switch (props.sorting) {
            case sortType.price:
                const p1 = s1.finish === cardFinish.nonfoil ? s1.card.price_usd :
                          (s1.finish === cardFinish.foil ? s1.card.price_usd_foil :
                          (s1.finish === cardFinish.etched ? s1.card.price_usd_etched : 0));
                const p2 = s2.finish === cardFinish.nonfoil ? s2.card.price_usd :
                          (s2.finish === cardFinish.foil ? s2.card.price_usd_foil :
                          (s2.finish === cardFinish.etched ? s2.card.price_usd_etched : 0));
                result = p1 - p2;
                break;
            case sortType.rarity:
                const r1 = s1.card.rarity in rarityValue ? rarityValue[s1.card.rarity] : 0;
                const r2 = s2.card.rarity in rarityValue ? rarityValue[s2.card.rarity] : 0;
                result = r1 - r2;
                break;
            case sortType.dateChanged:
                result = Date.parse(s1.date_changed) - Date.parse(s2.date_changed);
                break;
            case sortType.name:
                result = s1.card.name > s2.card.name ? 1 : -1;
                break;
            case sortType.collectorNumber:
            default:
                if (s1?.card?.set_id !== s2?.card?.set_id)
                    result = s2?.card?.set_id - s1?.card?.set_id;
                else
                    result = (parseInt(s1?.card?.c_number) > parseInt(s2?.card?.c_number) ? 1 : -1);
        }
        return (props.ordering === orderType.ascending ? result : -result);
    }

    const renderPacksList = () => {
        return (
            <div className="content scrollable">
                {!Object.keys(packs).length && (
                    <div className="no-samples">So far, it's empty</div>
                )}
                {Object.keys(packs)
                    .sort((id1, id2) => sortPacks(id1, id2))
                    .map((id, index) => renderRow(id))
                }
            </div>
        )
    }

    const renderSamplesList = () => {
        return (
            <div className="content scrollable">
                {!Object.keys(props.samples).length && (
                    <div className="no-samples">So far, it's empty</div>
                )}
                {Object.keys(props.samples)
                    .sort((id1, id2) => sortSamples(id1, id2))
                    .map((id, index) => renderSampleRow(props.samples[id]))
                }
            </div>
        )
    }

    const renderTilesList = () => {
        return (
            <div className="content scrollable">
                {!Object.keys(props.samples).length && (
                    <div className="no-samples">So far, it's empty</div>
                )}
                {Object.keys(props.samples)
                    .sort((id1, id2) => sortSamples(id1, id2))
                    .map((id, index) => renderSampleTile(props.samples[id], index))
                }
            </div>
        )
    }

    const renderSampleRow = (sample) => {
        return (
            <SampleRow
                key={sample.id}
                sample={sample}
                is_owned={props.is_owned}
                is_selected={props.selected_ids.has(sample.id)}
                active_sample_id={activeSample ? activeSample.id : 0}
                upd_sample_id={updatedSample ? updatedSample.id : 0}
                show_storage={props.show_storage}
                show_checkbox={props.show_checkbox}
                cutoffPrice={cutoffPrice}
                onClick={props.onActivate}
                onSelect={props.onSelect}
            />
        )
    }

    const renderRow = (id) => {
        const pack = packs[id];
        // Pack row
        if (pack.samples.length > 1) {
            return (
                <SamplePackRow
                    pack={pack}
                    is_owned={props.is_owned}
                    unfold={(packs_unfold.indexOf(pack.card.id) !== -1) ? true : false}
                    show_storage={props.show_storage}
                    onClick={handlePackToggle}
                    key={"pack_"+pack.card.id}
                >
                    {Object.keys(pack.samples)
                        .map((sample_id, index) => renderSampleRow(pack.samples[sample_id]))
                    }
                </SamplePackRow>
            )
        }
        // Sample row
        if (pack.samples.length === 1) {
            return renderSampleRow(pack.samples[0]);
        }
    }

    const renderSampleTile = (sample, index) => {
        return (
            <SampleTileDraggable
                type={ItemTypes.SAMPLE}
                key={sample.id}
                sample={sample}
                index={index}
                active_sample_id={activeSample ? activeSample.id : 0}
                upd_sample_id={updatedSample ? updatedSample.id : 0}
                cutoffPrice={cutoffPrice}
                onClick={props.onActivate}
            ></SampleTileDraggable>
        )
    }

    // FIXME: Droppable requires drag'n'drop context, which not implemented for all instances of SimpleList (e.g AllCardsPage)
    return (
        <div className="sample-list-content content scrollable">
        <Droppable
            droppableId='collection'
            type="TILE"
            isDropDisabled={false}
            //mode="virtual"
        >
            {(provided, snapshot) => (
            <div
                className={"storage-droppable"+(snapshot.isDraggingOver ? " dragged-over" : "")}
                ref={provided.innerRef}
                {...provided.droppableProps}
            >
                {(props.show_as_tiles && (
                    <div className="tiles-list">
                        {renderTilesList()}
                    </div>
                )) || (props.show_as_packs && (
                    <div className="packs-list">
                        {renderPacksList()}
                    </div>
                )) || (
                    <div className="samples-list">
                        {renderSamplesList()}
                    </div>
                )}
                <div>
                {provided.placeholder}
                </div>
            </div>
            )}
        </Droppable>
        </div>
    )

    // Prepare sample packs
    function getPacks(samples) {
        let packsRes = {}
        for (let id in samples) {
            let sample = samples[id];
            if (!(sample.card_id in packsRes)) {
                // Create a new pack and add to it
                packsRes[sample.card_id] = {
                    card: sample.card,
                    set: sample.set,
                    samples: [sample],
                    count: sample.qty,
                    storage_id: sample.storage_id,
                }
            }
            else {
                // Add to existing pack
                packsRes[sample.card_id].samples.push(sample)
                packsRes[sample.card_id].count += sample.qty
            }
        }
        return packsRes
    }

    function handlePackToggle(card_id) {
        let packsUnfoldUpd = [...packs_unfold];
        if (packsUnfoldUpd.indexOf(card_id) !== -1) {
            const index = packsUnfoldUpd.indexOf(card_id);
            packsUnfoldUpd.splice(index, 1);

            setPacksUnfold(packsUnfoldUpd);

            // Remove active if active sample inside pack
            if (activeSample && activeSample.card.id === card_id)
                    props.onActivate(null);
        } else {
            packsUnfoldUpd.push(card_id);

            setPacksUnfold(packsUnfoldUpd);
            if (card_id in packs) {
                const pack = packs[card_id];
                if (pack.samples.length)
                    props.onActivate(pack.samples[0]);
            }
        }
    }
}

SamplesListContent.propTypes = {
    // Samples list
    samples:            PropTypes.objectOf(PropTypes.object).isRequired,
    // Is this list owned by current user
    is_owned:           PropTypes.bool.isRequired,
    // Set of selected samples IDs
    selected_ids:       PropTypes.instanceOf(Set),
    // Flag that storage should be displayed for each sample
    show_storage:       PropTypes.bool,
    // Flag that checkbox should be displayed for each sample
    show_checkbox:      PropTypes.bool,
    // Flag that similar samples should be combined into packs
    show_as_packs:      PropTypes.bool,
    // Flag that cards shoul be represented as tiles
    show_as_tiles:      PropTypes.bool,
    // Selected sorting
    sorting:            PropTypes.string,
    // Direction of selected sort
    ordering:           PropTypes.number,
    // Callback on activate (select as active) sample
    onActivate:         PropTypes.func.isRequired,
    // Callback on select (with checkbox) sample
    onSelect:           PropTypes.func,
}

export default SamplesListContent