import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'

import './CmcPlaceholder.css'

import { ItemTypes } from '../../../utils/items'
import SampleTileDraggable from '../../../components/SamplesList/SampleTile/SampleTileDraggable'

function CmcPlaceholder(props) {

    useEffect(() => {
        console.log('init CmcPlaceholder "'+props.droppableId+'"', props.dummies)
    }, [props.droppableId, props.dummies])


    return (
        <div className="cmc-placeholder">
            <Droppable
                droppableId={props.droppableId}
                type="TILE"
                isDropDisabled={false}
            >
            {(provided, snapshot) => (
                <div
                    className={"cmc-droppable"+(snapshot.isDraggingOver ? " dragged-over" : "")}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                {Object.keys(props.dummies).map((key, index)=> (
                    <SampleTileDraggable
                        type={ItemTypes.DUMMY}
                        key={key}
                        sample={props.dummies[key]}
                        index={index}
                        active_sample_id={0}
                        upd_sample_id={0}
                        cutoffPrice={0}
                        onClick={()=>{}}
                    />
                ))}
                    <div>
                    {provided.placeholder}
                    </div>
                </div>
            )}
            </Droppable>
        </div>
    )
}

CmcPlaceholder.propTypes = {
    // Added dummies
    dummies:        PropTypes.array.isRequired,
    // Droppable id
    droppableId:    PropTypes.string.isRequired,
}

export default CmcPlaceholder