import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './AddSampleBlock.css'

import { useSample } from '../../hook/useSample'
import { useCard } from '../../hook/useCard'
import { scry } from '../../libs/scry'
import { notifier } from '../../libs/notifier'
import CardInfo from '../CardInfo/CardInfo'
import EditSampleBlock from '../EditSampleBlock/EditSampleBlock'
import SimpleInput from '../SimpleComponents/SimpleInput'
import SimpleSelect from '../SimpleComponents/SimpleSelect'
import CardPrintsList from '../CardPrintsList/CardPrintsList'
import SimpleLoadingSpinner from '../SimpleComponents/SimpleLoadingSpinner'


const searchMethods = {
    collectorNumber: "0",
    cardName: "1",
}

/**
 * Block for searching card and adding sample
 * @component
 */
const AddSampleBlock = (props) => {
    // "Search" form elements
    const [searchNumber, setSearchNumber] = useState('');
    const [searchCardname, setSearchCardname] = useState('');
    const [searchMessage, setSearchMessage] = useState('');
    const [searchMethod, setSearchMethod] = useState(searchMethods.collectorNumber);
    const [searchAutocomplete, setSearchAutocomplete] = useState([]);
    const [searchPrints, setSearchPrints] = useState([]);
    const [searchPrintsCount, setSearchPrintsCount] = useState(0);
    // "Add sample" form elements
    const [card, setCard] = useState(null);
    const [loading, setLoading] = useState(false);

    const {addSample} = useSample();
    const {searchCard} = useCard();


    useEffect(() => {
        console.log('Initialization: AddSampleBlock')
    }, [])


    const renderSearch = () => {
        switch (searchMethod) {
            case searchMethods.collectorNumber:
                return (
                    <div className = "collector-number">
                        <SimpleInput
                            type="text"
                            placeholder="e.g. BRO 123"
                            title="Code of the set and card number (can usually be found in the lower left corner of the card)"
                            value={searchNumber}
                            onChange={handleChangeCardNumber}
                            onKeyDown={handleKeydownSearch}
                            tabIndex={100}
                            autoFocus
                        />
                        <div className="star noselect" onClick={handleAddStar} title="Add ★ symbol">★</div>
                    </div>
                )
            case searchMethods.cardName:
                return (
                    <div className="card-name">
                        <SimpleInput
                            type="text"
                            placeholder="Card name"
                            title="Full or partial name of the card"
                            value={searchCardname}
                            onChange={handleChangeCardName}
                            onKeyDown={handleKeydownSearch}
                            tabIndex={100}
                        />
                    </div>
                )
            default:
                return null
        }
    }


    const getAutocompleteText = () => {
        let count = searchAutocomplete.length > 20 ? "20+" : searchAutocomplete.length;
        return count + " cards contains '" + searchCardname + "'";
    }


    return (
        <div className="add-sample-block">
            <div className="search">
                {renderSearch()}
                <div className="type">
                    <SimpleSelect
                        value={searchMethod}
                        onChange={handleChangeSearchMethod}
                        tabIndex={101}
                    >
                        <option value={searchMethods.collectorNumber}>Collector number</option>
                        <option value={searchMethods.cardName}>Card name</option>
                    </SimpleSelect>

                </div>
                <div className="button-search">
                    <div
                        className="mtgbutton"
                        onClick={processSearchCard}
                        onKeyDown={handleKeydownSearch}
                        tabIndex={102}
                    >
                        Search
                    </div>
                </div>
            </div>

            {loading && (
            <div className="loader">
                <SimpleLoadingSpinner/>
            </div>
            )}

            <div className="preview-flex">
                {card && (
                <CardInfo card={card}>
                    <EditSampleBlock
                        card={card}
                        sample={null}
                        language_default={props.last_language}
                        onConfirm={handleAddSample}
                        onCancel={props.onCancel}
                    />
                </CardInfo>
                )}
            </div>

            {searchMessage && (
                <div className="message">{searchMessage}</div>
            )}

            {searchAutocomplete.length > 0 && (
            <div className="autocomplete">
                <div className="title">{getAutocompleteText()}</div>
                <div className="content">
                    {searchAutocomplete.map((text, index) => (
                        <div className="record" key={index} onClick={()=>handleSelectAutocomplete(text)}>{text}</div>
                    ))}
                </div>
            </div>
            )}

            {searchPrintsCount > 0 && (
            <div className="prints-holder">
                <CardPrintsList
                    card={card}
                    cards_list={searchPrints}
                    prints_count={searchPrintsCount}
                    onSelect={handleSelectPrint}
                />
            </div>
            )}

            {/* <div className="spacer"></div> */}
        </div>
    )

    // Search card set input handling
    function handleChangeCardNumber(event) {
        setSearchNumber(event.target.value);
        setSearchAutocomplete([]);
        setSearchPrints([]);
        setSearchPrintsCount(0);
        setCard(null);
    }

    // Search by card name input handling
    function handleChangeCardName(event) {
        setSearchCardname(event.target.value);
        setSearchAutocomplete([]);
        setSearchPrints([]);
        setSearchPrintsCount(0);
        setCard(null);
    }

    // Add star symbol to card number handler
    function handleAddStar() {
        setSearchNumber(searchNumber + "★");
        setSearchAutocomplete([]);
        setSearchPrints([]);
        setSearchPrintsCount(0);
        setCard(null);
    }

    // Handle Enter press on any search of form elements
    function handleKeydownSearch(event) {
        if (event.key === 'Enter') {
            processSearchCard()
            event.preventDefault()
        }
        if (event.key === 'Escape') {
            props.onCancel?.();
        }
    }

    // Select search method
    function handleChangeSearchMethod(event) {
        setSearchMethod(event.target.value);
        setCard(null);
    }

    // Select variant from autocomplete list
    function handleSelectAutocomplete(text) {
        setSearchCardname(text);
        setSearchAutocomplete([]);
        setSearchPrints([]);
        setSearchPrintsCount(0);
        setCard(null);

        processSearchCardByName(text);
    }

    // Select card in print list
    function handleSelectPrint(scry_card) {
        showCard(scry_card.set, scry_card.collector_number)
    }

    function processSearchCard() {
        switch (searchMethod) {
            case searchMethods.collectorNumber:
                processSearchCardByNumber()
                break
            case searchMethods.cardName:
                processSearchCardByName(searchCardname);
                break
            default:
                notifier.logToConsole(notifier.level.warning, "Undefined search method")
        }
    }

    function processSearchCardByNumber() {
        // Prepare lazy input (when set code and collector number entered in first input)
        let cardSet = "";
        let cardNumber = "";
        let searchNumberTrimmed = searchNumber.trim();
        // Split by ,-+.; or space
        let re = /\s*,\s*|\s*-\s*|\s*\+\s*|\s*\.\s*|\s*;\s*|\s+/
        let parts = searchNumberTrimmed.split(re);
        // Use only spaces as delimiter if there is more than 2 parts
        if (parts.length > 2) {
            parts = searchNumberTrimmed.split(" ")
        }
        if (parts.length > 1) {
            cardSet = parts[0];
            // card_number = parts[1];
            // Join all parts after first one with a space
            cardNumber = parts.splice(1).join(" ");
            console.log('result:', cardSet, cardNumber)
            showCard(cardSet, cardNumber);
        } else {
            setSearchMessage('Enter the correct collector number. Example: BLB 123');
        }
    }

    function showCard(card_set, card_number) {
        setLoading(true);
        setSearchMessage('');

        // Perform searching
        searchCard(card_set, card_number, (status, data) => {
            if (status) { // Card Found
                setCard(data);
            } else { // Card not found => message
                setCard(null);
                if (data)
                    setSearchMessage(data);
            }
            setLoading(false);
        })
    }

    function processSearchCardByName(cardname) {
        // If provided card name too short
        if (cardname.length < scry.minsize) {
            setSearchMessage("Provide a longer name, please");
            return;
        }
        setLoading(true);
        setSearchMessage('');

        // Search by exact card name
        scry.searchByName(cardname, (status, card) => {
            console.log('card', card) //TODO: delete
            // If the exact card was found
            if (status) {
                // Retrieve all prints
                scry.getCardPrints(card, (status, prints_list) => {
                    if (status) {
                        setSearchPrints(prints_list.data);
                        setSearchPrintsCount(prints_list.total_cards);
                        setLoading(false);

                        showCard(card.set, card.collector_number);
                        return;
                    }
                    notifier.logToConsole(notifier.level.warning, "Can't load prints list for card '"+card.name+"'", true);
                })
                return;
            }

            // Autocomplete if exact card not found
            scry.autocomplete(cardname, (status, autocomplete) => {
                let msg = '';
                if (autocomplete.length === 0) {
                    msg = "Cards containing '"+cardname+"' were not found";
                }
                if (status) {
                    setSearchAutocomplete(autocomplete);
                    setLoading(false);
                    setSearchMessage(msg);
                }
            })
        })
    }

    function handleAddSample(sample_qty, sample_condition, sample_finish, sample_note, sample_language) {
        if (card) {
            addSample(card.id, props.storage.id, sample_qty, sample_condition,
                sample_finish, sample_note, sample_language, (sample) => {
                    if (sample) {
                        props.onConfirm?.(sample, sample_language);
                    }
                })
        }
    }
}

AddSampleBlock.propTypes = {
    // Target storage
    storage:        PropTypes.object.isRequired,
    // Last used language
    last_language:  PropTypes.string.isRequired,
    // Callback function on add sample, function(status, id)
    onConfirm:      PropTypes.func.isRequired,
    // Callback on cancel action
    onCancel:       PropTypes.func.isRequired,
}

export default AddSampleBlock