import React from 'react'
import PropTypes from 'prop-types'
import { Draggable } from "react-beautiful-dnd";

import { ItemTypeDelimiter } from '../../../utils/items.js';
import SampleTile from './SampleTile.js'

/**
 * Draggable version of sample tile
 * @component
 */
export const SampleTileDraggable = (props) => {
    const getTileClasses = (is_dragging) => {
        return is_dragging ? ' dragged' : ' not-dragged'
    }

    return (
        <Draggable
            key={props.sample.id}
            draggableId={props.type + ItemTypeDelimiter + props.sample.id}
            index={props.index}
        >
            {(provided, snapshot) => (
            <>
                <div
                    className={"drag-tile" + getTileClasses(snapshot.isDragging)}
                    //style={{cursor: snapshot.isDragging ? 'grabbing' : 'grab' }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <SampleTile
                        //is_dragging={snapshot.isDragging}
                        {...props}>
                    </SampleTile>
                </div>
                {snapshot.isDragging && (
                <div className="drag-tile copy">
                    <SampleTile
                        //is_dragging={snapshot.isDragging}
                        {...props}>
                    </SampleTile>
                </div>
                )}
            </>
            )}
        </Draggable>
    )
}

SampleTileDraggable.propTypes = {
    ...SampleTile.propTypes,
    // Draggable item type
    type:   PropTypes.string.isRequired,
}

export default SampleTileDraggable