import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'

import './CollectionsPage.css'

import { useAuth } from '../../hook/useAuth';
import { useStorage } from '../../hook/useStorage';
import { useSample } from '../../hook/useSample';
import { wrap } from '../../layouts/layouts';
import StorageListPanel from './StorageListPanel/StorageListPanel';
import StorageSamplesPanel from './StorageSamplesPanel/StorageSamplesPanel';
import ActiveSamplePanel from './ActiveSamplePanel/ActiveSamplePanel';
import ConfirmModal from '../../components/Modals/ConfirmModal/ConfirmModal';
import ModifyStorageModal from '../../components/Modals/ModifyStorageModal/ModifyStorageModal';
import MoveSamplesModal from '../../components/Modals/MoveSamplesModal/MoveSamplesModal'



/**
 * Collections management
 * @component
 */
const CollectionsPage = (props) => {
    const navigate = useNavigate();
    const {authenticated, user, signin} = useAuth();

    const {
        activeStorage,
        storages,
        addStorage,
        updateStorage,
        deleteStorage,
        exportStorage,
        loadingStorages,
    } = useStorage();

    const {
        activeSample,
        //updatedSample,
        samples,
        selectSample,
        //setUpdatedSample,
        //loadingSamples,
    } = useSample();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showModifyModal, setShowModifyModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    useEffect(() => {
        console.log('Initialization: CollectionsPage');
    }, [])

    useEffect(() => {
        // If storage deselected => Deselect sample
        if (!activeStorage) {
            selectSample(null);
            //setUpdatedSample(null);
        }
    }, [activeStorage, selectSample])

    const showStorageList = activeSample ? false : true;
    const showActiveSample = activeSample ? true : false;
    const public_storage = activeStorage ? (activeStorage.owner_id === user?.id ? false : true) : false;
    const is_owner = activeStorage ? (activeStorage.owner_id === user?.id ? true : false) : false;

    const title = activeStorage ? "Collection — " + activeStorage.name : "Collection";
    const upd_props = {...props, ...{title: title}}


    return wrap(props.layout, upd_props, (
        <div className="collections">
            <DragDropContext>
                {showStorageList && !public_storage && (
                    <StorageListPanel
                        storages={storages}
                        activeStorage={activeStorage}
                        loading={loadingStorages}
                        onActivateStorage={handleActivateStorage}
                        onAddStorageReq={handleAddStorage}
                        onModifyStorageReq={handleModifyStorage}
                        onMoveSamplesReq={handleMoveSamples}
                        onDeleteStorageReq={handleDeleteStorage}
                        onExportStorageReq={handleExportStorage}
                    />
                )}
                {showActiveSample && (
                    <ActiveSamplePanel
                        sample={activeSample}
                        onSelect={handleActivateSample}
                        onUpdate={handleUpdateSample}
                    />
                )}
                {activeStorage && (
                    <StorageSamplesPanel
                        isOwned={is_owner}
                        onActivateSample={handleActivateSample}
                        onUpdateSample={handleUpdateSample}
                    />
                )}
                <ModifyStorageModal
                    isOpen={showAddModal}
                    title="Add Storage"
                    storage={null}
                    isNew={true}
                    onResult={processAddStorage}
                />
                <ModifyStorageModal
                    isOpen={showModifyModal}
                    title="Modify Storage"
                    storage={activeStorage}
                    isNew={false}
                    onResult={processModifyStorage}
                />
                <MoveSamplesModal
                    isOpen={showMoveModal}
                    default_target={activeStorage}
                    samples={samples}
                    onConfirm={handleCloseMoveModal}
                    onCancel={handleCloseMoveModal}
                />
                <ConfirmModal
                    isOpen={showDeleteModal}
                    className="delete-strorage"
                    title="Delete storage"
                    text="Are you sure you want to delete the storage?"
                    defaultYes={false}
                    onResult={processDeleteStorage}
                >
                    <div className="storage-name">{activeStorage ? activeStorage.name : ""}</div>
                </ConfirmModal>
            </DragDropContext>
        </div>
    ))

    function handleActivateStorage(storage, callback) {
//console.log('handleActivateStorage', storage?.id);
        if (storage === null) {
            navigate(getCurrentURL(null))
        } else {
            navigate(getCurrentURL(storage))
        }
    }

    function handleActivateSample(sample) {
        if (!sample || // Deselect
            !activeSample || // No active sample
            activeSample.id !== sample.id) // Different sample activated
        {
            navigate(getCurrentURL(activeStorage, sample));
        }
    }

    function handleUpdateSample(sample) {
        handleActivateSample(sample);
    }

    function handleAddStorage() {
        if (authenticated) {
            setShowAddModal(true);
        } else {
            signin();
        }
    }

    function handleModifyStorage(storage) {
        navigate(getCurrentURL(storage));
        setShowModifyModal(true);
    }

    function handleMoveSamples(storage) {
        handleActivateStorage(storage);
        setShowMoveModal(true);
    }

    function handleCloseMoveModal() {
        setShowMoveModal(false);
    }

    function handleExportStorage(storage) {
        exportStorage(storage);
    }

    function processAddStorage(result){
        if (result.status) {
            addStorage(result.data.name, result.data.shortname, result.data.note, (newStorage) => {
                navigate(getCurrentURL(newStorage));
            })
        }
        setShowAddModal(false);
    }

    function processModifyStorage(result) {
        if (result.status) {
            updateStorage(activeStorage, result.data.name, result.data.shortname,
                result.data.note, result.data.hidden, result.data.public,
                (updStorage) => {
                    navigate(getCurrentURL(updStorage));
                });
        }
        setShowModifyModal(false);
    }

    function handleDeleteStorage(storage) {
        navigate(getCurrentURL(storage));
        setShowDeleteModal(true);
    }

    function processDeleteStorage(result) {
        setShowDeleteModal(false);
        if (result) {
            deleteStorage(activeStorage, (result) => {
                if (result) navigate(getCurrentURL(null));
            });
        }
    }

    // Get URL for current page
    function getCurrentURL(storage, sample) {
        if (storage) {
            if (sample)
                return "/collection/" + storage.id + "/"+sample.id;
            else
                return "/collection/" + storage.id;
        }
        return "/collection";
    }
}

CollectionsPage.propTypes = {
    // Page title for the browser tab
    title:              PropTypes.string.isRequired,
    // Page Layout
    layout:             PropTypes.string.isRequired,
}

export default CollectionsPage