import { api } from './configs/axiosConfigs';
import { defineCancelAPIObject, prepareErr } from './configs/axiosUtils';
import { notifier } from '../libs/notifier';

export const sampleAPI = {
    get: async function(storageId, cancel = false) {
        const url = "/samples";
        const formData = new FormData();
        formData.append("storage_id", storageId);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                samples: response.data.samples,
                cards: response.data.cards,
            }
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return {};
        }
    },

    add: async function(cardId, storageId, qty, condition, finish, note, language, cancel = false) {
        const url = "/samples/add";
        const formData = new FormData();
        formData.append("card_id", cardId);
        formData.append("storage_id", storageId);
        formData.append("qty", qty);
        formData.append("condition", condition);
        formData.append("finish", finish);
        formData.append("note", note);
        formData.append("language", language);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.add.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                sample: response.data.sample,
                card: response.data.card,
                storage: response.data.storage,
            }
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    update: async function(sampleId, storageId, qty, condition, finish, note, language, cancel = false) {
        const url = "/samples/update";
        const formData = new FormData();
        formData.append("id", sampleId);
        formData.append("storage_id", storageId);
        formData.append("qty", qty);
        formData.append("condition", condition);
        formData.append("finish", finish);
        formData.append("note", note);
        formData.append("language", language);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                sample: response.data.sample,
                card: response.data.card,
                storage: response.data.storage,
            }
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    move: async function(sampleIds, targetId, cancel = false) {
        const url = "/samples/move";
        const formData = new FormData();
        formData.append("sample_ids", JSON.stringify(sampleIds));
        formData.append("target_id", targetId);
        //formData.append("qty", qty); // TODO: qty ignored now

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.move.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                samplesUpd: response.data.updated_samples,
                samplesDel: response.data.deleted_samples,
                storages: response.data.storages,
            }
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    // var moveSample = function(sample_ids, target_id, callback) {
    //     let caller = notifier.getFunctionString('moveSample', [sample_ids, target_id, 'callback'])
    //     let sample_ids_json = JSON.stringify(sample_ids)
    //     $.ajax({
    //         type: 'POST',
    //         url: config.api_url+"/samples/move",
    //         data:{
    //             sample_ids: sample_ids_json,
    //             target_id: target_id,
    //             //qty: qty,// TODO: qty ignored now
    //         },
    //         dataType: "json",
    //         success: function(result) {
    //             if (result.status === "success") {
    //                 // Delete samples in cache
    //                 if (result.deleted_samples.constructor === Array) {
    //                     for (let del_id of result.deleted_samples) {
    //                         if (del_id in samples)
    //                             delete samples[del_id]
    //                     }
    //                 }
    //                 // Update samples in cache
    //                 if (result.updated_samples.constructor === Array) {
    //                     for (let sample of result.updated_samples) {
    //                         if (sample.id in samples) {
    //                             samples[sample.id].qty = sample.qty
    //                             samples[sample.id].storage_id = sample.storage_id
    //                         }
    //                     }
    //                 }
    //                 // Update storages (recalculations) in cache
    //                 if (result.storages.constructor === Array) {
    //                     for (let storage of result.storages) {
    //                         storages[storage.id] = storage
    //                     }
    //                     _callSubscription(dal.subs.storages)
    //                 }

    //                 // Callback subscription
    //                 _callSubscription(dal.subs.samples)

    //                 // Callback with result
    //                 if (callback) callback(true)
    //                 return
    //             }
    //             else
    //                 notifier.logToConsole(notifier.level.error, "Failed to move MTG card sample", true)
    //             if (callback) callback(false, result.message)
    //         },
    //         error: function(xhr, textStatus, errorThrown) {
    //             notifier.showAjaxError(xhr, textStatus, errorThrown, caller, 'moving MTG card sample')
    //             if (callback) callback(false)
    //         },
    //         timeout: 60000
    //     })
    // }



    delete: async function(sampleId, cancel = false) {
        const url = "/samples/delete";
        const formData = new FormData();
        formData.append("id", sampleId);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return{
                storage: response.data.storage,
            };
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    getSamplesForCard: async function(cardId, cancel = false) {
        const url = "/samples/getforcard";
        const formData = new FormData();
        formData.append("card_id", cardId);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.getSamplesForCard.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return{
                samples: response.data.samples,
                cards: response.data.cards,
            };
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },
}

// Show formatted API error
const showAPIErr = (url, message) => {
    let errMsg = "Error in sampleAPI call " + url +  " : " + message;
    notifier.logToConsole(notifier.level.error, errMsg, true);
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelAPIObject(sampleAPI);