import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './DeckListPanel.css'

import { useAuth } from '../../../hook/useAuth'
import { useDeck } from '../../../hook/useDeck'
import WorkspacePanel, {WorkspacePanelHead} from '../../../components/WorkspacePanel/WorkspacePanel'
import ModifyDeckModal from '../../../components/Modals/ModifyDeckModal/ModifyDeckModal'

/**
 * Deck List Component. Allows add, modify, delete decks
 * @component
 */
const DeckListPanel = (props) => {
    const {authenticated, signin} = useAuth();
    const {decks, activeDeck} = useDeck();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showModifyModal, setShowModifyModal] = useState(false);

    const getDeckRecordClass = (deck) => {
        let cls = "deck";
        if (activeDeck && deck.id === activeDeck.id)
            cls += " active";
        return cls
    }

    return (
        <WorkspacePanel className="decks-list noselect">
            <WorkspacePanelHead title="Decks">
                <div className="spacer"></div>
                <div className="mtgbutton button-add" onClick={handleAddDeck}>Add New</div>
            </WorkspacePanelHead>

            <div className="content scrollable">
            {(Object.keys(decks).length &&
                Object.keys(decks)
                .map((key, index) => (
                    <div
                        className={getDeckRecordClass(decks[key])}
                        key={decks[key].id}
                        onClick={()=>props.onSelectDeck(decks[key])}
                    >{decks[key].name}</div>
                ))
            ) || (
                <div className="no-decks">Decks haven't been added yet</div>
            )}
            </div>

            <ModifyDeckModal
                isOpen={showAddModal}
                title="Add Deck"
                deck={null}
                onResult={processAddDeck}
            />
            <ModifyDeckModal
                isOpen={showModifyModal}
                title="Modify Deck"
                deck={activeDeck}
                onResult={processModifyDeck}
            />
        </WorkspacePanel>
    )

    function handleAddDeck() {
        if (authenticated) {
            setShowAddModal(true);
        } else {
            signin();
        }
    }

    function processAddDeck(result) {
        if (result.status) {
            console.log('processAddDeck', result)
            //TODO: dal.addDeck(result.data.name, result.data.note)
        }
        setShowAddModal(false);
    }

    function processModifyDeck(result) {
        console.log('processModifyDeck', result)
    }

}

DeckListPanel.propTypes = {
    // Callback function on select storage
    onSelectDeck:   PropTypes.func.isRequired
}

export default DeckListPanel