import React from 'react'
import { BigNumber } from 'bignumber.js'
import PropTypes from 'prop-types'

import './SampleRow.css'

import { cardFinish, cardFinishText } from '../../../hoc/MtgProvider'
import { useMtg } from '../../../hook/useMtg'
import { useStorage } from '../../../hook/useStorage'
import SimpleCheckbox from '../../SimpleComponents/SimpleCheckbox'


/**
 * Sample row for sample list component
 * @component
 */
export const SampleRow = (props) => {
    const {
        getStorageTitleShort,
        getStorageTitleFull,
    } = useStorage();

    const {
        language,
        getShortCondition,
        getFullCondition,
    } = useMtg();

    return (
        <div
            className={"sample-list-row" + getSampleClasses()}
            onClick={handleClick}
        >
            {props.show_checkbox && (
                <SimpleCheckbox
                    state={props.is_selected}
                    onClick={handleCheckboxClick}
                />
            )}
            {(props.show_storage && props.is_owned) && (
            <div className="storage_code" title={"Storage: "+getStorageTitleFull(props.sample.storage_id)}>{getStorageTitleShort(props.sample.storage_id)}</div>
            )}
            <div className="card_number" title={getSetTitle()}>
                <span className="set_code">{props.sample.set ? props.sample.set.code : "?"}</span>&nbsp;
                <span className="c_number">{props.sample.card ? props.sample.card.c_number : "?"}</span>&nbsp;
            </div>
            <div className="language">{getLanguage()}</div>
            <div className="condition">
                <div className={"state "+(getShortCondition(props.sample))}
                    title={getFullCondition(props.sample)}
                >
                    {getShortCondition(props.sample)}
                </div>
            </div>
            <div className="qty">{props.sample.qty}&#8202;×</div>
            <div className={"name "+(props.sample.card ? props.sample.card.rarity : "")}>{props.sample.card ? props.sample.card.name : "Card Not Found"}</div>
            <div
                className={"finish finish-"+props.sample.finish}
                title={props.sample.finish in cardFinishText ? "Card is " + cardFinishText[props.sample.finish] : ""}
            >{props.sample.finish in cardFinishText ? cardFinishText[props.sample.finish].charAt(0) : ""}</div>
            <div className="note" title={props.sample.note ? "Note: " + props.sample.note : ""}><span>{props.sample.note}</span></div>
            {getPriceBlock()}
            {props.is_owned && (
            <div className="date_changed" title={"Last change: "+props.sample.date_added}>{props.sample.date_changed}</div>
            )}
        </div>
    )

    function getSampleClasses() {
        let cls = ""
        if (props.upd_sample_id)
            cls += props.upd_sample_id === props.sample.id ? " updated" : ""
        if (props.active_sample_id)
            cls += props.active_sample_id === props.sample.id ? " active" : ""
        return cls
    }

    function getSetTitle() {
        let language = ""
        try {
            language = language[props.sample.language].full
        }
        catch {
            language = "Unknown Language"
        }
        return props.sample.set ? (props.sample.set.name + " (" + language + ")") : "?"
    }

    function getLanguage() {
        let suffix = ""
        try {
            suffix = language[props.sample.language].short
        }
        catch {
            suffix = ""
        }
        if (suffix)
            return (<span className={"flag flag-"+props.sample.language}></span>)
        return (<span title="Unknown language">?</span>)
    }

    // Prepare price block
    function getPriceBlock() {
        if (!props.sample)
            return (<div className="price"></div>)
        if (!props.sample.card)
            return (<div className="price">?</div>)

        let price = BigNumber(0);
        switch (props.sample.finish) {
            case cardFinish.foil:
                price = BigNumber(props.sample.card.price_usd_foil);
                break;
            case cardFinish.etched:
                price = BigNumber(props.sample.card.price_usd_etched);
                break;
            case cardFinish.nonfoil:
            default:
                price = price = BigNumber(props.sample.card.price_usd);
                break;
        }

        if (price.lt(props.cutoffPrice))
            return (<div className="price" title={"Value: $"+BigNumber(price).toFormat(2)}>—</div>)

        let total = price.times(props.sample.qty)

        return (
            <div className="price">
                <div className="equation" title={"Price of stack of " + props.sample.qty + " card(s)"}>
                    ${total.toFormat(2)} = {props.sample.qty}&#8202;×&#8202;
                </div>
                <div className="total" title="Single card price">
                    <span className="usd">$&#8202;</span>{price.toFormat(2)}
                </div>
            </div>
        )
    }

    function handleClick(e) {
        props.onClick(props.sample)
    }

    function handleCheckboxClick(e) {
        if (props.onSelect) {
            e.preventDefault()
            props.onSelect(props.sample)
        }
    }
}

SampleRow.propTypes = {
    // Displayed sample
    sample:             PropTypes.object.isRequired,
    // Is this storage owned by current user
    is_owned:           PropTypes.bool.isRequired,
    // Is this sample selected
    is_selected:        PropTypes.bool,
    // ID of active sample
    active_sample_id:   PropTypes.number.isRequired,
    // ID of last updated sample
    upd_sample_id:      PropTypes.number.isRequired,
    // Cutoff price
    cutoffPrice:        PropTypes.number.isRequired,
    // Flag that the storage should be displayed
    show_storage:       PropTypes.bool,
    // Flag that selector should be displayed
    show_checkbox:      PropTypes.bool,
    // Callback function on click
    onClick:            PropTypes.func.isRequired,
    // Callback function on select
    onSelect:           PropTypes.func,
}

SampleRow.defaultProps = {
    is_selected: false,
    show_storage: true,
    show_checkbox: false,
}

export default SampleRow