import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import './CardListFilter.css'

import { useMtg } from '../../hook/useMtg'
import { useCard } from '../../hook/useCard'



/**
 * Filter for MTG cards list
 * @component
 */
const CardListFilter = ({className}) => {
    const {sets} = useMtg();
    const {filter, setFilter, applyFilter} = useCard();

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        console.log('Initialization: CardListFilter');
    }, [])

    const getSortedFilteredSetsList = useCallback(() => {
        return Object.keys(sets)
                .sort((a, b) => sets[a].released_at < sets[b].released_at ? 1 : -1) // Sort: Newest first
                .filter(key => sets[key].set_type === 'core' || sets[key].set_type === 'expansion') // Only core and expansion sets
    }, [sets])

    const getActiveClass = (code) => {
        if (filter?.set_code?.indexOf(code) !== -1)
            return ' active';
        return '';
    }
    const getTitle = (cardset) => {
        return `${cardset.name} (${cardset.released_at.split('-')[0]})`
    }

    return (
        <div className={"card-list-filter" + (className ? " " + className : "")}>
            <div className={"sets-list" + (isExpanded ? " expanded": "")}>
                {getSortedFilteredSetsList()
                    .map((key, index) => (
                    <div
                        key={key}
                        className={"set-icon"+getActiveClass(sets[key].code)}
                        onClick={() => {onSelect(sets[key].code)}}
                        //order={1}
                    >
                        <img src={sets[key].icon_svg_uri} alt={sets[key].code} title={getTitle(sets[key])}/>
                    </div>
                ))}
                <div key="more" className="more" onClick={handleExpand}></div>
            </div>
            <div className="card-name">
                <input
                    type="text"
                    value={filter.name}
                    placeholder="Card Name Filter"
                    onChange={handleFilterNameChange}
                />
            </div>
        </div>
    )

    function onSelect(code) {
        if (!filter.set_code.includes(code)) {
            let filterUpd = {...filter};
            filterUpd.set_code = [code];
            //console.log('select', code);
            applyFilter(filterUpd);

            setIsExpanded(false);
        }
    }

    function handleFilterNameChange(event) {
        let filterUpd = {...filter}
        filterUpd.name = event.target.value;
        setFilter(filterUpd)
    }

    function handleExpand() {
        setIsExpanded(true);
    }
}

CardListFilter.propTypes = {
    // Additional class name
    className:          PropTypes.string,
}

export default CardListFilter