import React from 'react'
import PropTypes from 'prop-types'

import './OrderingSwitch.css'

export const orderType = {
    ascending: 0,
    descending: 1,
}

/**
 * Ordering switch
 * @component
 */
export const OrderingSwitch = (props) => {
    const cls = "ordering-switch " +
                (props.state === orderType.descending ? "descending" : "ascending");
    return(
        <div className={cls}
            onClick={props.onClick}
            title={cls.charAt(0).toUpperCase() + cls.slice(1) + " ordering"}
        ></div>
    )
}

OrderingSwitch.propTypes = {
    // Ordering state
    state:      PropTypes.number.isRequired,
    // On click callback function
    onClick:    PropTypes.func.isRequired,
}

export default OrderingSwitch