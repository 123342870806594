import { api } from './configs/axiosConfigs';
import { prepareErr, defineCancelAPIObject } from './configs/axiosUtils';
import { notifier } from '../libs/notifier';

let requestId = 0;

export const cardAPI = {
    getCards: async function(filter, cancel = false) {
        requestId += 1;
        const url = "/cards";
        const formData = new FormData();
        formData.append("filter", JSON.stringify(filter));
        formData.append("request_id", requestId);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.getCard.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            if (response?.data?.request_id === requestId) {
                return{
                    cards: response.data.cards,
                };
            } else {
                return null;
            }
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    searchCard: async function(setCode, collectorNumber, cancel = false) {
        const url = "/cards/search";
        const formData = new FormData();
        formData.append("set_code", setCode);
        formData.append("collector_number", collectorNumber);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.searchCard.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                card: response.data.card,
            }
        } else if (response?.data?.status === "fail") {
            return {
                message: response.data.message,
            };
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return {
                message: "Unspecified error",
            };
        }
    },
}

// Show formatted API error
const showAPIErr = (url, message) => {
    let errMsg = "Error in cardAPI call " + url +  " : " + message;
    notifier.logToConsole(notifier.level.error, errMsg, true);
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelAPIObject(cardAPI);