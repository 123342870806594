import React from 'react'
import './CenteredBlock.css'



/**
 * Centered block for content placed inside relatively positioned parent
 * @component
 */
 export const CenteredBlock = (props) => {
    return(
        <div className="centered-block">
            {props.children}
        </div>
    )
}

export default CenteredBlock