import { useCallback } from "react";
import { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { storageAPI } from "../apis/storageAPI";
import { download } from '../libs/helper'

//import { dal } from '../libs/dal'
//import { storageAPI } from '../../apis/storageAPI';

export const StorageContext = createContext(null);

export const StorageProvider = ({children}) => {
    const params = useParams();
    const [activeStorage, setActiveStorage] = useState(null);
    const [storages, setStorages] = useState({});
    const [loadingStorages, setLoadingStorages] = useState(true);
    const [storageFilter, setStorageFilter] = useState(storageFilters.visible);
    const [storageOrder, setStorageOrder] = useState(storageOrders.name);

    useEffect(() => {
        console.log('Initialization: StorageProvider');
        storageAPI.getAll().then((resp_storages) => {
            let storagesUpd = {}
            // Apply storages to cache
            for (let i = 0; i < resp_storages.length; i++)
                storagesUpd[resp_storages[i].id] = resp_storages[i]
            setStorages(storagesUpd);
            setLoadingStorages(false);
        })
    }, [])

    useEffect(() => {
        if (params.storage_id) {
            if (params.storage_id in storages)
                setActiveStorage(storages[params.storage_id]);
            else {
                //console.warn("Selected storage #"+params.storage_id+" not loaded => request");
                storageAPI.get(params.storage_id).then((storage) => {
                    if (storage) {
                        setActiveStorage(storage);
                    } else {
                        setActiveStorage(null);
                    }
                })
            }
        }
    }, [storages, params.storage_id]);

    const selectStorage = useCallback(
        (storage) =>{
            setActiveStorage(storage);
    }, [])

    function addStorage(name, shortname, note, onResult) {
        storageAPI.add(name, shortname, note).then((resp_storage) => {
            if (resp_storage) {
                let storagesUpd = {...storages};
                storagesUpd[resp_storage.id] = resp_storage;
                setStorages(storagesUpd);
                if (onResult) onResult(storagesUpd[resp_storage.id])
            } else {
                if (onResult) onResult(null);
            }
        })
    }

    function updateStorage(storage, name, shortname, note, hidden, public_, onResult) {
        storageAPI.update(storage, name, shortname, note, hidden, public_).then((resp_storage) => {
            if (resp_storage) {
                let storagesUpd = {...storages};
                delete storagesUpd[storage.id];
                storagesUpd[storage.id] = resp_storage;
                setStorages(storagesUpd);
                if (activeStorage.id === resp_storage.id) {
                    setActiveStorage(resp_storage);
                }
                if (onResult) onResult(storagesUpd[resp_storage.id]);
            } else {
                if (onResult) onResult(null);
            }
        })
    }

    function deleteStorage(storage, onResult) {
        storageAPI.delete(storage.id).then((resp_result) => {
            if (resp_result) {
                // Update storages list
                let storagesUpd = {...storages};
                delete storagesUpd[storage.id];
                setStorages(storagesUpd);
                if (onResult) onResult(true);
            } else {
                if (onResult) onResult(false);
            }
        })
    }

    function exportStorage(storage, onResult) {
        storageAPI.export(storage.id).then((resp_result) => {
            if (resp_result) {
                let today = new Date();
                let timestamp = today.getFullYear() +
                                ('0' + (today.getMonth() + 1)).slice(-2) +
                                ('0' + today.getDate()).slice(-2);
                download("export_storage_"+storage.id+"_"+timestamp+".csv", resp_result)
                if (onResult) onResult(true);
            } else {
                if (onResult) onResult(false);
            }
        })
    }

    /**
     * Refresh values in cache without changing serverside
     */
    function refreshStorages(storagesChngd) {
        if (storagesChngd.length) {
            let storagesUpd = {...storages};
            for (let i = 0; i < storagesChngd.length; i++) {
                if (activeStorage.id === storagesChngd[i].id) {
                    setActiveStorage(storagesChngd[i]);
                }
                storagesUpd[storagesChngd[i].id] = storagesChngd[i];
            }
            setStorages(storagesUpd);
        }
    }


    function getStorageTitleShort(storageId) {
        if (storageId in storages)
            return storages[storageId].shortname;
        return '?';
    }


    function getStorageTitleFull(storageId) {
        if (storageId in storages)
            return storages[storageId].name;
        return "Can't find storage of this sample";
    }


    const value = {
        activeStorage,
        storages,
        selectStorage,
        addStorage,
        updateStorage,
        deleteStorage,
        exportStorage,
        refreshStorages,
        loadingStorages,
        getStorageTitleShort,
        getStorageTitleFull,
        storageFilter, setStorageFilter,
        storageOrder, setStorageOrder,
    };

    return (
        <StorageContext.Provider value={value}>
            {children}
        </StorageContext.Provider>
    )
}

export const storageFilters = {
    all: 'All',
    visible: 'Not hidden',
    public: 'Public',
}

export const storageOrders = {
    name: 'Name',
    shortname: 'Shortname',
    value: 'Value',
}