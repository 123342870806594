import React from 'react'
import PropTypes from 'prop-types'

import './SimpleCheckbox.css'


/**
 * This simple checkbox component
 * @component
 */
export const SimpleCheckbox = (props) => {
    const cls = "checkbox " +
                (props.state ? " yes": " no") +
                (props.className ? " " + props.className : "");
    return(
        <div
            className={cls}
            onClick={props.onClick}
            title={props.title}
        >
            <span className="yes">☑️</span>
            <span className="no">⬜</span> {props.label}
        </div>
    )
}

SimpleCheckbox.propTypes = {
    // Check state
    state:      PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    // Label text
    label:      PropTypes.string,
    // Title html attribute for component
    title:      PropTypes.string,
    // Additional class
    className:  PropTypes.string,
    // Callback function on click
    onClick:    PropTypes.func.isRequired,
}

export default SimpleCheckbox