import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './EditSampleBlock.css'

import { cardFinish } from '../../hoc/MtgProvider'
import { PropTypesCustom } from '../../libs/helper'
import { SimpleSelect } from '../SimpleComponents/SimpleSelect'
import SimpleTextarea from '../SimpleComponents/SimpleTextarea'

const MAX_CARD_NOTE_LENGTH = 1000



/**
 * Block for editing sample information
 * @component
 */
const EditSampleBlock = (props) => {
    const [qty, setQty] = useState(props.sample ? props.sample.qty : 1);
    const [condition, setCondition] = useState(props.sample ? props.sample.condition : 1);
    const [finish, setFinish] = useState(props.sample ? parseInt(props.sample.finish) : -1);
    const [note, setNote] = useState(props.sample ? props.sample.note : '');
    const [language, setLanguage] = useState(props.sample ? props.sample.language : props.language_default);

    useEffect(() => {
        console.log('Initialization: EditSampleBlock');
    }, [])

    useEffect(() => {
        if (props.sample) {
            setFinish(props.sample.finish);
            return
        }
        // Select first available finish for selected card
        let finishUpd = -1;
        if (props.card.nonfoil) {
            finishUpd = cardFinish.nonfoil;
        } else if (props.card.foil) {
            finishUpd = cardFinish.foil;
        } else if (props.card.etched) {
            finishUpd = cardFinish.etched;
        }
        setFinish(finishUpd)
    }, [props.card, props.sample])

    return (
        <div className="card-action">
            <SimpleTextarea
                placeholder="Any notes if required…"
                value={note}
                title="Sample notes"
                className="sample-notes"
                onChange={handleChangeCardNote}
                onKeyDown={handleKeyDown}
                limit = {MAX_CARD_NOTE_LENGTH}
                tabIndex={103}
            />
            <SimpleSelect
                value={""+condition}
                onChange={handleChangeCondition}
                onKeyDown={handleKeyDown}
                tabIndex={104}
            >
                <option value="1">Near Mint (NM)</option>
                <option value="2">Light Played (LP)</option>
                <option value="3">Moderately Played (MP)</option>
                <option value="4">Heavily Played (HP)</option>
                <option value="5">Damaged (D)</option>
            </SimpleSelect>

            <SimpleSelect
                value={""+finish}
                onChange={handleChangeFinish}
                onKeyDown={handleKeyDown}
                tabIndex={105}
            >
                <option value="0" disabled={!props.card['nonfoil']}>Non-Foil</option>
                <option value="1" disabled={!props.card['foil']}>Foil</option>
                <option value="2" disabled={!props.card['etched']}>Etched</option>
            </SimpleSelect>

            <div className="row">
            <div className="spacer"></div>
                <SimpleSelect
                    className="language"
                    value={language}
                    onChange={handleChangeLanguage}
                    onKeyDown={handleKeyDown}
                    tabIndex={106}
                >
                    <option value="en">English</option>
                    <option value="zhs">Chinese (simpl.)</option>
                    <option value="zht">Chinese (trad.)</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="it">Italian</option>
                    <option value="ja">Japanese</option>
                    <option value="ko">Korean</option>
                    <option value="pt">Portuguese</option>
                    <option value="ru">Russian</option>
                    <option value="es">Spanish</option>
                </SimpleSelect>
                <div className="quantity">
                <div className="minus noselect" onClick={()=>handleChangeQty2(-1)}>&minus;</div>
                    <input
                        value={qty}
                        onChange={handleChangeQty}
                        onKeyDown={handleKeydownQty}
                        tabIndex={106}
                        autoFocus
                    />
                    <div className="plus noselect" onClick={()=>handleChangeQty2(1)}>+</div>
                </div>
            </div>

            <div className="row">
                <div className="spacer"></div>
                <div className="buttons-wrapper">
                    {(props.sample && (
                    <div className="mtgbutton save" tabIndex={107} onClick={handleConfirm} onKeyDown={handleKeyDownConfirm}>Save</div>
                    )) || (
                    <div className="mtgbutton add" tabIndex={107} onClick={handleConfirm} onKeyDown={handleKeyDownConfirm}>Add to Collection</div>
                    )}
                    <div className="mtgbutton cancel grey" tabIndex={107} onClick={handleCancel} onKeyDown={handleKeyDownCancel}>Cancel</div>
                </div>
            </div>
        </div>
    )

    // Handling of input for note textarea
    function handleChangeCardNote(event) {
        let shortened = event.target.value.slice(0, MAX_CARD_NOTE_LENGTH);
        setNote(shortened);
    }
    // Handling of condition select
    function handleChangeCondition(event) {
        setCondition(parseInt(event.target.value));
    }
    // Handle of finish select
    function handleChangeFinish(event) {
        setFinish(parseInt(event.target.value));
    }
    // Handle of language select
    function handleChangeLanguage(event) {
        setLanguage(event.target.value);
    }
    // Handle of qty input
    function handleChangeQty(event) {
        setQty(event.target.value);
    }
    // Handle buttons for qty input increment/decrement
    function handleChangeQty2(step) {
        let qty_upd = normalizeQty(qty) + step;
        qty_upd = (qty_upd > 0 ? qty_upd : 1);
        setQty(qty_upd);
    }
    // Cast qty to integer
    function normalizeQty(qty_upd) {
        return (isNaN(qty_upd) ? 1 : parseInt(qty_upd))
    }
    // Handle Enter as Confirm, Escape as Cancel
    function handleKeyDownConfirm(e) {
        if (e.key === 'Enter')
            handleConfirm()
        handleKeyDown(e)
    }
    // Handle Enter as Cancel, Escape as Cancel
    function handleKeyDownCancel(e) {
        if (e.key === 'Enter')
            handleCancel()
        handleKeyDown(e)
    }
    // Handle Escape as Cancel
    function handleKeyDown(e) {
        if (e.key === 'Escape')
            handleCancel()
    }
    // Key handling for quantity input
    function handleKeydownQty(e) {
        if (e.key === 'Enter')
            handleConfirm()
        if (e.key === 'Escape')
            handleCancel()
        if (e.key === 'ArrowUp') {
            setQty(parseInt(qty) + 1);
            e.preventDefault();
        }
        if (e.key === 'ArrowDown') {
            let upd_qty = parseInt(qty) - 1;
            upd_qty = upd_qty > 0 ? upd_qty : 1;
            setQty(upd_qty);
            e.preventDefault();
        }
    }

    function handleConfirm() {
        // Restrict finish to supported only
        let finishUpd = finish
        let unsupportedFinish = false;
        switch (finish) {
            case cardFinish.nonfoil:
                unsupportedFinish = props.card.nonfoil ? false : true;
                break;
            case cardFinish.foil:
                unsupportedFinish = props.card.foil ? false : true;
                break;
            case cardFinish.etched:
                unsupportedFinish =  props.card.etched ? false : true;
                break;
            default:
                break;
        }
        // If selected finish not supported by the card => select supported
        if (unsupportedFinish) {
            if (props.card.nonfoil) {
                finishUpd = cardFinish.nonfoil;
                console.log("Finish changed to nonfoil");
            } else if (props.card.foil) {
                finishUpd = cardFinish.foil;
                console.log("Finish changed to foil");
            } else if (props.card.etched) {
                finishUpd = cardFinish.etched;
                console.log("Finish changed to etched");
            } else {
                console.error("Selected unsupported finish: ", finish)
                return
            }
        }
        // Prepare positive integer quantity
        let qtyUpd = normalizeQty(qty)
        qtyUpd = qtyUpd > 0 ? qtyUpd : 1

        // Perform confirmation
        if (props.onConfirm)
            props.onConfirm(qtyUpd, condition, finishUpd, note, language)
    }

    function handleCancel() {
        if (props.onCancel)
            props.onCancel()
    }
}

EditSampleBlock.propTypes = {
    // Card for this sample
    card:               PropTypes.object.isRequired,
    // Selected sample (may be null)
    sample:             PropTypesCustom.object_or_null_required,
    // Default language (if sample is null, e.g. when adding new sample)
    language_default:   PropTypes.string.isRequired,
    // Callback function on confirm sample editing
    onConfirm:          PropTypes.func.isRequired,
    // Callback function on cancel sample editing
    onCancel:           PropTypes.func.isRequired,
}

export default EditSampleBlock