import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './MoveSamplesModal.css'

import { useSample } from '../../../hook/useSample'
import ModalBase from '../ModalBase'
import SamplesList, {viewModeList as sampleView} from '../../SamplesList/SamplesList'
import { sortType } from '../../SamplesList/SampleListContent'
import { useStorage } from '../../../hook/useStorage'
//import { dal } from '../../../libs/dal'


const moveModalStyles = {
    content: {
        width: '600px',
        maxHeight: '99%',
    }
}


/**
 * Modal dialog for samples move
 * @component
 */
const MoveSamplesModal = (props) => {
    const {storages} = useStorage();
    const {moveSample} = useSample();

    const [targetStorageId, setTargetStorageId] = useState(() => {
        // Prepare initial target storage
        if (props.default_target)
            return props.default_target.id;
        else if (storages.length)
            return storages[Object.keys(storages)[0]].id;
        return 0;
    })

    const [selectedIds, setSelectedIds] = useState(() => {
        // Prepare selected samples Set
        let selected = new Set()
        for (let sample_id in props.samples) {
            selected.add(props.samples[sample_id].id)
        }
        return selected
    })

    useEffect(() => {
        // Clearing the selected items if samples have changed
        let selected = new Set()
        for (let sample_id in props.samples) {
            selected.add(props.samples[sample_id].id)
        }
        setSelectedIds(selected)
    }, [props.samples])

    const sortByName = (k1, k2) => {
        return storages[k1].name > storages[k2].name ? 1 : -1
    }

    //console.log('MoveSamplesModal', this.props.samples)
    return (
        <ModalBase
            isOpen={props.isOpen}
            className="modal-move-samples"
            onRequestClose={props.onCancel}
            style={moveModalStyles}
        >
            <div className="modal-header">
                <div className="modal-title">Move samples</div>
                <div className="modal-close-x" onClick={props.onCancel}></div>
            </div>
            <div className="modal-content">
                <div className="panel">
                    <SamplesList
                        samples={props.samples}
                        is_owned={true}
                        active_sample={null}
                        updated_sample={null}
                        selected_ids={selectedIds}
                        viewMode={sampleView.rows}
                        show_storage={false}
                        show_checkbox={true}
                        show_change_mode={false}
                        show_subhead={true}
                        default_sort={sortType.collectorNumber}
                        onActivate={()=>{}}
                        onSelect={handleSelect}
                    />
                </div>
            </div>
            <div className="modal-control">
                <div className="target">
                    <div className="label">Move to: </div>
                    <select value={targetStorageId} onChange={handleChangeTarget}>
                        {Object.keys(storages)
                            .sort((k1, k2) => sortByName(k1, k2))
                            .map((key, index)=> (
                                <option key={key} value={storages[key].id}>{storages[key].name}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="buttons-wrapper">
                    <input
                        type="button"
                        className="mtgbutton"
                        title="Cancel"
                        onClick={props.onCancel}
                        autoFocus={props.defaultYes ? false : true}
                        value="Cancel"/>
                    <input
                        type="button"
                        className="mtgbutton"
                        title="Confirm"
                        onClick={handleConfirm}
                        autoFocus={props.defaultYes ? true : false}
                        value="Confirm"/>
                </div>
            </div>
        </ModalBase>
    )

    function handleSelect(sample, select_all=false, select_none=false){
        if (select_all) {
            let selected_upd = new Set()
            for (let sample_id in props.samples) {
                selected_upd.add(props.samples[sample_id].id)
            }
            setSelectedIds(selected_upd)
            return
        }
        if (select_none) {
            setSelectedIds(new Set())
            return
        }
        // Prepare new selected state
        let selected_upd = new Set(selectedIds)
        if (selected_upd.has(sample.id))
            selected_upd.delete(sample.id)
        else
            selected_upd.add(sample.id)
        // Update state
        setSelectedIds(selected_upd)
    }

    function handleChangeTarget(event) {
        setTargetStorageId(event.target.value)
    }

    function handleConfirm() {
        if (targetStorageId && selectedIds.size) {
            //console.log("Move samples: ", selectedIds)
            //console.log("To:", targetStorageId)
            moveSample(Array.from(selectedIds), targetStorageId, () => {
                props.onConfirm();
            })
        } else {
            console.warn("Select samples to move")
        }
    }
}

MoveSamplesModal.propTypes = {
    // Open state of modal
    isOpen:         PropTypes.bool.isRequired,
    // Default target storage object
    default_target: PropTypes.object,
    // Associative array (object) of samples for moving
    samples:        PropTypes.objectOf(PropTypes.object).isRequired,
    // Show grouped samples if this flag is set
    show_group:     PropTypes.bool,
    // Callback function for submit move
    onConfirm:      PropTypes.func,
    // Callback function for cancel move
    onCancel:       PropTypes.func,
}

export default MoveSamplesModal