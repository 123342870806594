import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './ModifyDeckModal.css'

import { PropTypesCustom } from '../../../libs/helper'
import SimpleInput from '../../SimpleComponents/SimpleInput'
import SimpleTextarea from '../../SimpleComponents/SimpleTextarea'
import ModalBase from '../ModalBase'

const modifyDeckModalStyles = {
    content: {
        width: '350px'
    }
}

const max_name_length = 250
const max_note_length = 1000



/**
 * Modify deck parameters modal
 * @component
 */
const ModifyDeckModal = (props) => {
    const [nameValue, setNameValue] = useState("");
    const [noteValue, setNoteValue] = useState("");

    useEffect(() => {
        console.log('Init ModifyDeckModal');
    }, []);

    useEffect(() => {
        if (props.deck) {
            setNameValue(props.deck.name);
            setNoteValue(props.deck.note);
        } else {
            setNameValue("");
            setNoteValue("");
        }
    }, [props.deck]);

    return(
        <ModalBase
            isOpen={props.isOpen}
            className="modal-modify-deck"
            onRequestClose={handleCancel}
            style={modifyDeckModalStyles}
        >
            <div className="modal-header">
                <div className="modal-title">{props.title}</div>
                <div className="modal-close-x" onClick={handleCancel}></div>
            </div>

            <div className="modal-content">
                <SimpleInput
                    type="text"
                    placeholder="Deck name"
                    value={nameValue}
                    title="Deck name"
                    onChange={handleChangeNameValue}
                    onKeyDown={handleKeyDown}
                    tabIndex={1}
                    limit={max_name_length}
                />
                <SimpleTextarea
                    placeholder="Any notes if required…"
                    value={noteValue}
                    title="Deck notes"
                    className="deck-notes"
                    onChange={handleChangeNote}
                    onKeyDown={handleKeyDown}
                    limit = {max_note_length}
                />
            </div>
            <div className="modal-control">
                <div className="mtgbutton" title="Close" onClick={handleCancel}>Close</div>
                <div className="mtgbutton" title="Save" onClick={handleSave}>Save</div>
            </div>
        </ModalBase>
    )

    // Apply changes
    function handleSave() {
        props.onResult({
            status: true,
            data: {
                name: nameValue,
                note: noteValue,
            }
        });
    }

    // Cancel changes
    function handleCancel() {
        props.onResult({
            status: false,
        })
        // TODO: Reset current values to deck values
    }

    function handleChangeNameValue(event) {
        const value = event.target.value.substring(0, max_name_length);
        setNameValue(value);
    }

    function handleChangeNote(event) {
        const value = event.target.value.substring(0, max_note_length);
        setNoteValue(value);
    }

    // Process Enter key
    function handleKeyDown(event) {
        if (event.key === 'Enter'){
            handleSave();
            event.preventDefault();
        }
    }
 }

 ModifyDeckModal.propTypes = {
    // Open state of modal
    isOpen:         PropTypes.bool.isRequired,
    // Modal title
    title:      PropTypes.string.isRequired,
    // Deck object (or null)
    deck:       PropTypesCustom.object_or_null_required,
    // Callback function on submit
    onResult:   PropTypes.func.isRequired,
}

 export default ModifyDeckModal