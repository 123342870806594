import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'

import './AllCardsPage.css'

import { useAuth } from '../../hook/useAuth'
import { useMtg } from '../../hook/useMtg'
import { useCard } from '../../hook/useCard'
import { useSample } from '../../hook/useSample'
import { wrap } from '../../layouts/layouts'
import { sortType } from '../../components/SamplesList/SampleListContent'
import CardInfo from '../../components/CardInfo/CardInfo'
import CardListFilter from '../../components/CardListFilter/CardListFilter'
import SamplesList, { viewModeList } from '../../components/SamplesList/SamplesList'
import WorkspacePanel from '../../components/WorkspacePanel/WorkspacePanel'
import Spinner from '../../components/SimpleComponents/Spinner'




/**
 * List of all existing MTG cards
 * @component
 */
const AllCardsPage = (props) => {
    const {authenticated} = useAuth();
    const {sets} = useMtg();
    const {samples, getSamplesForCard} = useSample();
    const {cards, loadingCards, filter} = useCard();

    const [loadingSamples, setLoadingSamples] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);


    useEffect(() => {
        console.log('Initialization: AllCardsPage');
    }, [])

    const getSetCode = (setId) => {
        if (setId in sets)
            return sets[setId].code;
        return "?";
    }

    const sortCards = (card1, card2) => {
        // Compare sets IDs
        if (card1.set_id !== card2.set_id)
            return card1.set_id > card2.set_id ? 1 : -1
        // Compare collection numbers (even if it contains text)
        return parseInt(card1.c_number) > parseInt(card2.c_number) ? 1 : -1
    }

    return wrap(props.layout, props,(
            <div className="all-cards">
                <WorkspacePanel className="card-list">
                    <CardListFilter
                        className="head"
                    />
                    {(loadingCards && (
                    <div className="cards-spinner">
                        <Spinner />
                    </div>
                    )) || (
                    <div className="card-list-holder scrollable">
                        {cards
                            .sort((card1, card2) => sortCards(card1, card2)) //(a.c_number < b.c_number ? -1: 1))// Sort: Newest first
                            .filter(obj => (obj.name.toLowerCase().includes(filter?.name?.toLowerCase())))
                            .map((card, index)=>(
                                <div className={"card noselect" + ((selectedCard && card.id === selectedCard.id) ? " selected": "") + " " + card.rarity}
                                    key={card.id}
                                    onClick={() => {handleSelectCard(card)}}
                                >
                                    <div className="card-number">{getSetCode(card.set_id)} {card.c_number}</div>
                                    <div className="card-name">{card.name}</div>
                                </div>
                        ))}
                    </div>
                    )}
                </WorkspacePanel>
                {selectedCard && (
                <DragDropContext>
                    <WorkspacePanel className="selected-card">
                        <div className="head">
                            <div className="title">Selected card</div>
                        </div>
                        <CardInfo
                            card={selectedCard}
                        />
                        {(authenticated && loadingSamples) && (
                        <div className="samples-spinner">
                            <Spinner/>
                        </div>
                        )}
                        {(authenticated && Object.keys(samples).length > 0) && (
                        <div className="card-presence">
                            <div className="subhead">This card in your collection:</div>
                            <SamplesList
                                samples={samples}
                                is_owned={true}
                                active_sample={null}
                                updated_sample={null}
                                viewMode={viewModeList.rows}
                                show_subhead={false}
                                default_sort={sortType.storage}
                                onActivate={handleActivateSample}
                                />
                        </div>
                        )}
                    </WorkspacePanel>
                </DragDropContext>
                )}
            </div>
    ))

    function handleSelectCard(card) {
        setLoadingSamples(true);
        if (authenticated) {
            getSamplesForCard(card.id, (samples) => {
                setLoadingSamples(false);
                //console.log('loaded samples', samples)
            })
        }
        setSelectedCard(card);
    }

    function handleActivateSample(sample) {
    }
}

AllCardsPage.propTypes = {
    // Page title for the browser tab
    title:          PropTypes.string.isRequired,
    // Page Layout
    layout:         PropTypes.string.isRequired,
}

export default AllCardsPage