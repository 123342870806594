import { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { deckAPI } from "../apis/deckAPI";
import { useMtg } from "../hook/useMtg";

export const DeckContext = createContext(null);

export const DeckProvider = ({children}) => {
    const params = useParams();

    const [decks, setDecks] = useState({});
    const [activeDeck, setActiveDeck] = useState(null);
    const [dummies, setDummies] = useState(null);

    const {sets} = useMtg();

    useEffect(() => {
        deckAPI.getDecks().then((resp) => {
            let decksUpd = {};
            for (let deck of resp.decks) {
                decksUpd[deck.id] = deck;
            }
            setDecks(decksUpd);
        })
        console.log('Initialization: DeckProvider');
    }, [])

    useEffect(() => {
        let deckUpd = null;
        if (params.deck_id in decks) {
            deckUpd = decks[params.deck_id];
        }
        setActiveDeck(deckUpd);
    }, [decks, params.deck_id])

    useEffect(() => {
        // Load dummies
        if (activeDeck) {
            deckAPI.getDummies(activeDeck.id).then((resp) => {
                // Cards
                let d_cards = {};
                for (let i = 0; i < resp.cards.length; i++) {
                    const card = resp.cards[i];
                    d_cards[card.id] = card;
                }
                // Samples
                let d_samples = {};
                for (let i = 0; i < resp.samples.length; i++) {
                    const sample = resp.samples[i];
                    sample.card = sample.card_id in d_cards ? d_cards[sample.card_id] : null;
                    sample.set = sample.card && sample.card.set_id in sets ? sets[sample.card.set_id] : null;
                    d_samples[sample.id] = sample;
                }
                // Dummies
                let dummiesUpd = {}
                for (let i = 0; i < resp.dummies.length; i++) {
                    const dummy = resp.dummies[i];
                    if (dummy.sample_id) {
                        dummy.sample = d_samples[dummy.sample_id];
                    }
                    dummy.card = d_cards[dummy.card_id];
                    dummiesUpd[dummy.id] = dummy;
                }
                //console.log(dummiesUpd);
                setDummies(dummiesUpd);
            })
        } else{
            setDummies({});
        }
        console.log('Changed deck ->', activeDeck);
    }, [activeDeck, sets])


    function updateDeck(deck, {name, note, last_storage_id}, onResult) {
        if (deck) {
            const d_name = (typeof name !== 'undefined' ? name : deck.name);
            const d_note = (typeof note !== 'undefined' ? note : deck.note);
            const d_last_storage_id = (typeof last_storage_id !== 'undefined' ? last_storage_id : deck.last_storage_id);

            console.log('name: ', d_name, name);
            console.log('note', d_note, note);
            console.log('last_storage_id', d_last_storage_id, last_storage_id)

            deckAPI.updateDeck(deck.id, d_name, d_note, d_last_storage_id, dummies).then((resp) => {
                if (resp && resp.deck) {
                    let deckUpd = resp.deck;
                    let decksUpd = {...decks};
                    delete decksUpd[deckUpd.id];
                    decksUpd[deckUpd.id] = deckUpd;
                    setDecks(decksUpd);
                    if (activeDeck?.id === deckUpd.id)
                        setActiveDeck(deckUpd);

                    if (onResult) onResult(deckUpd);
                } else {
                    if (onResult) onResult(null);
                }
            })
        }
    }

    function addDummy(deck_id, card_id, placeholder, sample_id) {
        deckAPI.addDummy(deck_id, card_id, placeholder, sample_id).then((resp) => {
            console.log('addDummy', resp)
        })
    }

    const value = {
        decks,
        activeDeck,
        updateDeck,
        dummies,
        addDummy,
    }

    return (
        <DeckContext.Provider value={value}>
            {children}
        </DeckContext.Provider>
    )
}