import { api } from './configs/axiosConfigs';
import { prepareErr, defineCancelAPIObject } from './configs/axiosUtils';
import { notifier } from '../libs/notifier';


export const authAPI = {
    connect: async function(cancel = false) {
        const url = "/connectioncheck";
        const response = await api.post(url, {}, {
            signal: cancel ? cancelApiObject[this.connect.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            let result = {};
            result.connected = true;
            result.authenticated = response.data.authenticated ? true : false;
            result.user = {};
            if (response.data.authenticated) {
                for (const[key, value] of Object.entries(response.data.authenticated)) {
                    result.user[key] = value;
                }
            }
            //result.version = response.data.version;
            result.lastUpdate = response.data.last_update;
            return result;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    logout: async function(cancel = false) {
        const url = "/logout";
        const response = await api.post(url, {}, {
            signal: cancel ? cancelApiObject[this.logout.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return true;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return false;
        }
    },

//     /**
//      * Logout user
//      */
//     var logout = function(callback) {
//         let caller = notifier.getFunctionString('logout', ['callback'])
//         $.ajax({
//             type: 'POST',
//             url: config.api_url+"/logout",
//             dataType: "json",
//             success: function(result) {
//                 if (result.status === "success") {
//                     if (callback) callback(true)
//                 }
//                 else {
//                     notifier.logToConsole(notifier.level.error, "Failed to logout", true)
//                     if (callback) callback(false)
//                 }
//                 _checkConnection()
//             },
//             error: function(xhr, textStatus, errorThrown) {
//                 notifier.showAjaxError(xhr, textStatus, errorThrown, caller, 'logout')
//                 if (callback) callback(false)
//                 _checkConnection()
//             },
//             timeout: 20000
//         })
//     }

}

// Show formatted API error
const showAPIErr = (url, message) => {
    let errMsg = "Error in authAPI call " + url +  " : " + message;
    notifier.logToConsole(notifier.level.error, errMsg, true);
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelAPIObject(authAPI);