import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import './TopMenu.css'

import { useMtg } from '../../../hook/useMtg'
import { useAuth } from '../../../hook/useAuth'
import { isNumber } from '../../../libs/helper'
import SimpleInput from '../../../components/SimpleComponents/SimpleInput'



/**
 * Top menu
 * @component
 */
const TopMenu = (props) => {
    const [cutoffPriceNan, setCutoffNan] = useState(false);
    const [cutoffPriceValue, setCutoffValue] = useState();
    const [cutoffPriceChanged, setCutoffChanged] = useState(false);

    const {authenticated, signin, signout} = useAuth();
    const {version, cutoffPrice, setCutoffPrice} = useMtg();

    useEffect(() => {
        setCutoffValue(cutoffPrice);
    }, [cutoffPrice])

    return (
        <div className="layout-topmenu">
            <div className="wrapper">
                <NavLink
                    end to="/"
                    className="logo"
                    title={"MTG Keeper v"+version.frontend}></NavLink>
                <div className="menu-holder">
                    <NavLink
                        end to="/all"
                        className={({isActive}) => {return "menu-item" + (isActive ? " active":"")}}
                        title="Browse all Magic cards">All cards</NavLink>
                    <NavLink
                        to="/collection"
                        className={({isActive}) => {return "menu-item" + (isActive ? " active":"")}}
                        title="Manage your collection">Collection</NavLink>
                    {/* <NavLink
                        to="/decks"
                        className={({isActive}) => {return "menu-item" + (isActive ? " active":"")}}
                        title="Construct your deck">Decks</NavLink> */}
                </div>
                <div className="cut-off">
                    $
                    <SimpleInput
                        type="text"
                        className={(cutoffPriceNan ? "nan" : "") + (cutoffPriceChanged ? " changed" : "")}
                        value={""+cutoffPriceValue}
                        title="The price limit below which the price is not taken into account"
                        onChange={handleChangePrice}
                        onKeyDown={handleKeydownPrice}
                    />
                </div>
                <div className="signin">
                    {(authenticated && (
                        <span className="mtgbutton grey" onClick={signout}>Sign out</span>
                    )) || (
                        <span className="mtgbutton grey" onClick={signin}>Sign in</span>
                    )}
                </div>
            </div>
        </div>
    )

    function handleChangePrice(event) {
        setCutoffNan(false);
        setCutoffChanged(true);
        setCutoffValue(event.target.value);
    }
    function handleKeydownPrice(event) {
        if (event.key === 'Enter') {
            if (isNumber(cutoffPriceValue)) {
                const cutoffPriceNumber = Number(cutoffPriceValue);
                setCutoffPrice(cutoffPriceNumber);
                setCutoffChanged(false);
            }
            else {
                setCutoffNan(true);
            }
        }
    }
}

TopMenu.propTypes = {
}

export default TopMenu