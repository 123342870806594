import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'

import { ItemTypes } from '../../../utils/items'

function CmcSpacer(props) {

    useEffect(() => {
        console.log('init CmcSpacer "'+props.droppableId+'"')
    }, [props.droppableId])


    return (
        <div className="cmc-spacer">
            <Droppable
                droppableId={props.droppableId}
                type="TILE"
                isDropDisabled={false}
            >
            {(provided, snapshot) => (
                <div
                    className={"cmc-droppable"+(snapshot.isDraggingOver ? " dragged-over" : "")}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <div>
                    {provided.placeholder}
                    </div>
                </div>
            )}
            </Droppable>
        </div>
    )
}

CmcSpacer.propTypes = {
    // Droppable id
    droppableId:    PropTypes.string.isRequired,
    // Type

}

export default CmcSpacer