import { isNumeric } from "jquery"

/* Модуль нотификаций */
export var notifier = (function() {
    var onNotification = null // Обработчик уведомлений
    var onServerLog = null // Обработчик записи в лог
    //var notification_handlers = [] // Список обработчиков уведомлений

    var notify_errors = true    // Показывать ли уведомления при ошибках

    /**
     * Отобразить уведомление
     * @param {String|Number} level Уровень уведомления: debug, [info | notice], [warning | attention], error, [critical | fatal] или числовое значение из notifier.notification_level
     * @param {String} text         Текст уведомления
     * @param {String} [title]      Текст заголовка уведомления
     * @param {Boolean} [log]       Флаг добавления в лог
     */
    var notify = function(level, text, title="", console_log=false, server_log=false) {
        //Если не установлен текст сообщения
        if (!text) return
        // Получаем уровень сообщения в виде цифры
        level = getNumericLevel(level)

        // Если не установлен обработчик
        // Если включено логирование
        if (!onNotification || console_log) {
            if (!onNotification)
                console.warn('Обработчик уведомлений не установлен')
            if (console_log)
                logToConsole(level, "Уведомление: " + (title ? "|" + title + "| " : "") + text, server_log)
            else if (server_log) {
                logToServer(level, "Уведомление: " + (title ? "|" + title + "| " : "") + text)
            }

            // if (level === notifier.notification_level.debug || level === notifier.notification_level.info || level === notifier.notification_level.success) {
            //     console.log("Уведомление: " + text)
            //     if (onLogging) onLogging('info', "Уведомление: " + text)
            // } else if (level === notifier.notification_level.warning) {
            //     console.warn("Уведомление: " + text)
            //     if (onLogging) onLogging('warning', "Уведомление: " + text)
            // } else if (level === notifier.notification_level.error || level === notifier.notification_level.critical) {
            //     console.error("Уведомление: " + text)
            //     if (onLogging) onLogging('error', "Уведомление: " + text)
            // }
        }
        // Вызываем обработчик нотификаций
        if (onNotification)
            onNotification(level, text, title)
    }

    /**
     * Добавить лог-запись в консоль
     * @param {String|Number} level Уровень сообщения: debug, [info | notice], [warning | attention], error, [critical | fatal] или числовое значение из notifier.notification_level
     * @param {String} text         Текст сообщения
     * @param {Boolean} server_log  Флаг отправления лога на сервер терминала
     */
    var logToConsole = function(level, text, server_log=false) {
        // Если не установлен текст сообщения
        if (!text) return
        // Получаем уровень сообщения в виде цифры
        level = getNumericLevel(level)
        // Делаем запись в консоль методом зависящим от уровня сообщения
        switch(level) {
            case notifier.level.debug:
                console.log(text)
                break
            case notifier.level.info:
            case notifier.level.success:
                console.info(text)
                break
            case notifier.level.warning:
                console.warn(text)
                break
            case notifier.level.error:
                console.error(text)
                break
            default:
                break
        }
        // Если включено логирование на сервер, то запускаем его
        if (server_log)
            logToServer(level, text)
    }

    /**
     * Отправить лог-запись на сервер
     * @param {String|Number} level Уровень уведомления
     * @param {String} text         Текст сообщения
     */
    var logToServer = function(level, text) {
        // Если не установлен текст сообщения
        if (!text) return
        // Если установлен обработчик логирования на сервер
        if (onServerLog) {
            // Получаем уровень уведомления в виде цифры
            level = getNumericLevel(level)
            // Отправляем лог-запись на сервер
            onServerLog(level, text)
        }
    }

    /**
     * Получить численное значение уровня уведомления
     * @param {String|Number} level Уровень уведомления
     * @returns {Number}            Уровень уведомления в виде notifier.notification_level
     */
    var getNumericLevel = function(level) {
        // Численный уровень, значит уровень уже установлен
        if (isNumeric(level))
            return level
        // Возвращаем соответствующий номер уровня
        if (typeof level === 'string') {
            switch (level) {
                case 'debug':
                    return notifier.level.debug
                case 'info':
                case 'notice':
                    return notifier.level.info
                case 'warning':
                case 'attention':
                    return notifier.level.warning
                case 'error':
                    return notifier.level.error
                case 'critical':
                case 'fatal':
                    return notifier.level.critical
                default:
                    break
            }
        }
        // Уровень не задан, возвращаем значение по умолчанию
        if (typeof level === 'undefined')
            return notifier.level.info
        // Задан некорректный уровень уведомления
        console.warn('Задан некорректный уровень логирования (применён уровень INFO):', level)
        return notifier.level.info
    }

    /**
     * Возвратить сформированное текстовое представление текущей функции
     * @param {Object[]} args Список аргументов
     */
    var getFunctionString = function(fname, args) {
        /*let fname = ""
        try {
            let error = new Error('test')
            console.log('Error: ', error)
            fname = error.stack.match(/at (\S+)/g)[1] // Выбираем предпоследнюю функцию из стека
        }
        catch (e) {
            console.exception(e)
        }*/
        //fname.replace('at Object.', '').replace('at ', '') // удаляем "at Object." или "at "
        // Добавляем аргументы
        if (args.constructor === Array)
            return fname+"("+args.join(', ')+")"
        else {
            console.warn('Невозможно вывести аргументы функции '+fname+': убедитесь, что передан список(Array) аргументов')
            return fname+"(...)"
        }
    }

    /**
     * Добавить в лог ошибку с заданным текстом и функцией, где она случилась
     * @param {String} text         Текст описания ошибки
     * @param {String} caller       Текстовое представление функции в которой произошла ошибка
     * @param {String} [prefix]     Текст перед описанием ошибки
     * @param {String} [title]      Заголовок ошибки
     * @param {Boolean} [suppress]  Флаг запрета вывода всплывающего уведомления (только логирование)
     */
    var showError = function(text, caller, prefix="Ошибка: ", title="", suppress=false) {
        // Сообщение для лога
        let log_msg = prefix + title + "\n" + text.charAt(0).toLowerCase()+text.substr(1)
        // Добавляем сообщение об ошибке в консоль и на сервер
        logToConsole(notifier.level.error, log_msg, true)
        // Добавляем функцию вызова в консоль и на сервер
        if (caller)
            logToConsole(notifier.level.info, "Функция " + caller, true)
        // Выводим нотификацию об ошибке, если включено
        if (notify_errors && !suppress)
            notifier.notify(notifier.level.error, text, title, false, false)
    }


    /**
     * Добавить в лог ошибку выполнения AJAX-запроса с функцией, где она случилась
     * @param {String} xhr Объект ошибки (jQuery.ajax)
     * @param {String} textStatus Статус операции (jQuery.ajax)
     * @param {String} errorThrown Текст ошибки (jQuery.ajax)
     * @param {String} caller Текстовое представление функции в которой произошла ошибка
     * @param {String} [title] Текст для заголовка ошибки
     */
    var showAjaxError = function(xhr, textStatus, errorThrown, caller, title="") {
        showError(xhr.status+" ("+xhr.statusText+")\nServer Response:\n"+xhr.responseText, caller, "AJAX-request error: ", title, true)
    }


    /**
     * Установить обработчик уведомлений. В установленную функцию вдальнейшем передаются (level, message, title)
     * @param {Function} handler Функция обратного вызова для обработки уведомлений
     */
    var setNotificationHandler = function(handler) {
        onNotification = handler
    }

    var setServerLogHandler = function(handler) {
        onServerLog = handler
    }

return{
    level: {
        debug: 0,
        info: 1,
        success: 2,
        warning: 3,
        error: 4,
        critical: 5,
    },

    notify: notify,
    logToConsole: logToConsole,
    logToServer: logToServer,

    getFunctionString: getFunctionString,
    showError: showError,
    showAjaxError: showAjaxError,

    // Установка внешних обработчиков уведомлений
    setNotificationHandler: setNotificationHandler,
    setServerLogHandler: setServerLogHandler
}

})()