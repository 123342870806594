import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import './ContentPage.css'
import { wrap } from '../../layouts/layouts'
import { useMtg } from '../../hook/useMtg'



/**
 * Static content page
 * @component
 */
const ContentPage = (props) => {
    const [content, setContent] = useState("");
    const {getContent} = useMtg();

    useEffect(() => {
        getContent('index', (content) => {
            setContent(content);
        })
    }, [getContent])

    return wrap("default", props, (
        <div className="content-holder">
            <div className="content-wrapper">
                <div className="content-page" dangerouslySetInnerHTML={{__html: content}}></div>
            </div>
        </div>
    ))

}

ContentPage.propTypes = {
    // Page title for the browser tab
    title:  PropTypes.string.isRequired,
}

export default ContentPage