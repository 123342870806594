import React, { useState } from 'react'

import './Footer.css'

import { useMtg } from '../../../hook/useMtg'
import DonateModal from '../../../components/Modals/DonateModal/DonateModal'


/**
 * Footer element
 * @component
 */
const Footer = (props) => {
    const [showDonateModal, setShowDonateModal] = useState(false);
    const {lastUpdate} = useMtg();

    const handleDonateOpen = () => {
        setShowDonateModal(true)
    }
    const handleDonateClose = () => {
        setShowDonateModal(false)
    }

    return (
        <div className="layout-footer">
            {lastUpdate && (
            <div className="update">Last update: {lastUpdate} UTC</div>
            )}
            {/* <div>Contact <a href="https://t.me/artem_pavlov">me</a> on Telegram</div> */}
            <div className="spacer"></div>
            <span className="link support" onClick={handleDonateOpen}>Support the project</span>

            <DonateModal
                isOpen={showDonateModal}
                onRequestClose={handleDonateClose}
            />
        </div>
    )
}

export default Footer;