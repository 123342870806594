import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './ModifyStorageModal.css'

import { PropTypesCustom } from '../../../libs/helper'
import ModalBase from '../ModalBase'
import SimpleCheckbox from '../../SimpleComponents/SimpleCheckbox'
import SimpleInput from '../../SimpleComponents/SimpleInput'
import SimpleTextarea from '../../SimpleComponents/SimpleTextarea'

const renameModalStyles = {
    content: {
        top: '250px',
        width: '350px'
    }
}

const max_storage_name_length = 100
const max_storage_shortname_length = 6
const max_storage_note_length = 1000


/**
 * Modify storage parameters modal
 * @component
 */
const ModifyStorageModal = (props) => {

    const [nameValue, setNameValue] = useState("");
    const [shortnameValue, setShortnameValue] = useState("");
    const [noteValue, setNoteValue] = useState("");
    const [hiddenValue, setHiddenValue] = useState(0);
    const [publicValue, setPublicValue] = useState(0);

    useEffect(() => {
        console.log('Initialization: ModifyStorageModal')
    }, [])

    useEffect(() => {
        setNameValue(props.storage ? props.storage.name : "");
        setShortnameValue(props.storage ? props.storage.shortname : "");
        setNoteValue(props.storage ? props.storage.note : "");
        setHiddenValue(props.storage ? props.storage.hidden : 0);
        setPublicValue(props.storage ? props.storage.public : 0);

    }, [props.storage])

    return (
        <ModalBase
            isOpen={props.isOpen}
            className="modal-rename"
            onRequestClose={handleCancel}
            style={renameModalStyles}
        >
            <div className="modal-header">
                <div className="modal-title">{props.title}</div>
                <div className="modal-close-x" onClick={handleCancel}></div>
            </div>
            <div className="modal-content">
                <SimpleInput
                    type="text"
                    placeholder="Name"
                    value={nameValue}
                    title="Collection name"
                    onChange={handleChangeNameValue}
                    limit={max_storage_name_length}
                    autoFocus={true}
                />
                <SimpleInput
                    type="text"
                    placeholder="Short Name"
                    value={shortnameValue}
                    title="Short name for collection"
                    onChange={handleChangeShortnameValue}
                    limit={max_storage_shortname_length}
                />
                <SimpleTextarea
                    placeholder="Any notes if required…"
                    value={noteValue}
                    title="Notes"
                    className="note"
                    onChange={handleChangeNote}
                    limit = {max_storage_note_length}
                />
                {(!props.isNew) && (
                <div>
                    <SimpleCheckbox
                        state={hiddenValue}
                        label="Hidden"
                        title="The storage will be hidden from summary and grayed out in the list"
                        className="hidden"
                        onClick={handleChangeHiddenState}
                    />
                    <SimpleCheckbox
                        state={publicValue}
                        label="Public"
                        title="This storage will be publicly visible as one of your collections"
                        className="public"
                        onClick={handleChangePublicState}
                    />
                </div>
                )}
            </div>
            <div className="modal-control">
                <div className="buttons-wrapper">
                    <button className="mtgbutton" title="Save" onClick={handleSave}>Save</button>
                    <button className="mtgbutton" title="Close" onClick={handleCancel}>Close</button>
                </div>
            </div>
        </ModalBase>
    )

    // Reset input to defaults
    function reset() {
        setNameValue("");
        setShortnameValue("");
        setNoteValue("");
        setHiddenValue(0);
        setPublicValue(0);
    }

    function handleCancel() {
        props.onResult({status: false});
        reset();
    }

    // Apply changes and clear form data
    function handleSave() {
        props.onResult({
            status: true,
            data: {
                name: nameValue,
                shortname: shortnameValue,
                note: noteValue,
                hidden: hiddenValue,
                public: publicValue,
            }
        })
        reset();
    }

    function handleChangeNameValue(event) {
        let shortened = event.target.value.slice(0, max_storage_name_length);
        setNameValue(shortened);
    }

    function handleChangeShortnameValue(event) {
        let shortened = event.target.value.slice(0, max_storage_shortname_length);
        setShortnameValue(shortened);
    }

    function handleChangeNote(event) {
        let shortened = event.target.value.slice(0, max_storage_note_length);
        setNoteValue(shortened);
    }

    function handleChangeHiddenState() {
        setHiddenValue(hiddenValue ? 0 : 1);
    }

    function handleChangePublicState() {
        setPublicValue(publicValue ? 0 : 1);
    }
}

ModifyStorageModal.propTypes = {
    // Open state of modal
    isOpen:         PropTypes.bool.isRequired,
    // Modal title
    title:          PropTypes.string.isRequired,
    // Storage object (or null)
    storage:        PropTypesCustom.object_or_null_required,
    // Flag of "add new" storage (hidden/public checkboxes not shown)
    isNew:          PropTypes.bool,
    // Callback function on submit
    onResult:       PropTypes.func.isRequired,
}

export default ModifyStorageModal