import $ from 'jquery'
import { notifier } from './notifier.js'

export var scry = (function() {
    var config = {
        api_url: "https://api.scryfall.com",
    }

    var autocomplete = function(name, callback) {
        let caller = notifier.getFunctionString('scry.autocomplete', [name, 'callback'])
        if (name.length < scry.minsize) {
            callback(false)
            return
        }

        $.ajax({
            type: 'GET',
            url: config.api_url+"/cards/autocomplete?q="+name,
            dataType: "json",
            success: function(result) {
                if ('data' in result) {
                    if (callback) callback(true, result.data)
                    return
                }
                notifier.logToConsole(notifier.level.error, "No data received from scryfall", true)
                if (callback) callback(false)
            },
            error: function(xhr, textStatus, errorThrown) {
                notifier.showAjaxError(xhr, textStatus, errorThrown, caller, 'scryfall autocomplete')
                if (callback) callback(false)
            },
            timeout: 20000
        })
    }

    var searchByName = function(exactname, callback) {
        let caller = notifier.getFunctionString('scry.searchByName', [exactname, 'callback'])
        $.ajax({
            type: 'GET',
            url: config.api_url+"/cards/named?exact="+exactname,
            dataType: "json",
            success: function(result) {
                if (callback) callback(true, result)
            },
            error: function(xhr, textStatus, errorThrown) {
                if (xhr.status === 404) {
                    if (callback) callback(false, null)
                    return
                }
                notifier.showAjaxError(xhr, textStatus, errorThrown, caller, 'scryfall searchByName')
                if (callback) callback(false)
            },
            timeout: 20000
        })
    }

    var getCardPrints = function(scry_card, callback) {
        let caller = notifier.getFunctionString('scry.getCardPrints', [scry_card, 'callback'])
        if (!('prints_search_uri' in scry_card)) {
            callback(false)
        }

        $.ajax({
            type: 'GET',
            url: scry_card.prints_search_uri,
            dataType: "json",
            success: function(result) {
                if (callback) callback(true, result)
            },
            error: function(xhr, textStatus, errorThrown) {
                notifier.showAjaxError(xhr, textStatus, errorThrown, caller, 'scryfall getCardPrints')
                if (callback) callback(false)
            },
            timeout: 20000
        })
    }

return {
    minsize: 3,

    autocomplete: autocomplete,
    searchByName: searchByName,
    getCardPrints: getCardPrints,
}
})()