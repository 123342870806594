import DefaultLayout from './DefaultLayout/DefaultLayout'
import RawLayout from './RawLayout/RawLayout'


export const wrap = (layout="default", props, content) => {
    switch(layout) {
        case 'default':
            return (
                <DefaultLayout {...props}>
                    {content}
                </DefaultLayout>
            )
        case 'raw':
            return (
                <RawLayout {...props}>
                    {content}
                </RawLayout>
            )

        default: // Unsupported layout
            console.warn("Unsupported layout: ", layout)
            return (
                <>{content}</>
            )
    }
}