import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

import './ModalBase.css'



/**
 * Base modal window
 * @component
 */
const ModalBase = (props) => {
    return (
        <Modal isOpen={props.isOpen}
            className={"modal-base " + (props.className ? props.className : "")}
            overlayClassName={"modal-overlay-base " + (props.overlayClassName ? props.overlayClassName : "")}
            onAfterOpen={props.onAfterOpen}
            onRequestClose={props.onRequestClose}
            style={props.style}
            appElement={document.getElementById('root')}
        >
            {props.children}
        </Modal>
    )
}

ModalBase.propTypes = {
    // Open state
    isOpen:             PropTypes.bool,
    // Custom class
    className:          PropTypes.string,
    // Custom style object
    style:              PropTypes.object,
    // Custom class for overlay
    overlayClassName:   PropTypes.string,
    // Callback on opening dialog
    onAfterOpen:        PropTypes.func,
    // Callback on close dialog request
    onRequestClose:     PropTypes.func,
}

export default ModalBase