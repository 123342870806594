import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './AddSampleModal.css'

import ModalBase from '../ModalBase'
import AddSampleBlock from '../../../components/AddSampleBlock/AddSampleBlock'


const addSampleModalStyles = {
    content: {
        // width: '100%',
        // maxWidth: '600px',
        maxHeight: '99%',
    }
}


/**
 * Adding new sample modal
 * @component
 */
const AddSampleModal = (props) => {
    const [lastLanguage, setLastLanguage] = useState('en');

    return (
        <ModalBase isOpen={props.isOpen}
            className="modal-addsample"
            onRequestClose={props.onRequestClose}
            style={addSampleModalStyles}
        >
            <AddSampleBlock
                storage={props.storage}
                last_language={lastLanguage}
                onConfirm={handleAddSample}
                onCancel={props.onRequestClose}
            />
        </ModalBase>
    )

    function handleAddSample(sample, language) {
        setLastLanguage(language);
        props.onAddSample(sample);
    }
}

AddSampleModal.propTypes = {
    // Open state of modal
    isOpen:         PropTypes.bool.isRequired,
    // The storage to which the sample will be added
    storage:        PropTypes.object.isRequired,
    // Callback function on confirm the addition (added sample passed)
    onAddSample:    PropTypes.func.isRequired,
    // Callback on close dialog request
    onRequestClose: PropTypes.func.isRequired,
}

export default AddSampleModal