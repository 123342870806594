import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './DonateModal.css'
import btc from '../../../assets/btc.png'
import eth from '../../../assets/eth.png'

import ModalBase from '../ModalBase'

const donateModalStyles = {
    content: {
        //top: '250px',
        maxWidth: '450px',
        minWidth: '320px',
    }
}

/**
 * Donation (support the project) modal window
 * @component
 */
const DonateModal = (props) => {
    const [selected, setSelected] = useState('');

    const handleSelectCrypto = (selected_upd) => {
        if (selected !== selected_upd) {
            setSelected(selected_upd);
        }
        //console.log('selected: ', selected_upd)
    }
    const handleRequestClose = () => {
        setSelected('')
        props.onRequestClose?.()
    }

    return (
        <ModalBase
            isOpen={props.isOpen}
            className="modal-donate"
            onRequestClose={handleRequestClose}
            style={donateModalStyles}
        >
            <div className="modal-header">
                <div className="modal-title">Contribute at MTG Keeper project</div>
                <div className="modal-close-x" onClick={handleRequestClose}></div>
            </div>
            <div className="modal-content">
                <div className="block">
                    <div className="line">Donate to our hosting to keep the project working</div>
                    <div className="line hosting">
                        <span className="payment-icons">
                            <a href="https://ruvds.com/en-usd/pay/aa1b0fb3be164d71b215a146bff0408a"><span className="vs"></span>Visa</a>
                            <a href="https://ruvds.com/en-usd/pay/aa1b0fb3be164d71b215a146bff0408a"><span className="mc"></span>MasterCard</a>
                            <a href="https://ruvds.com/en-usd/pay/aa1b0fb3be164d71b215a146bff0408a"><span className="pp"></span>PayPal</a>
                        </span>
                    </div>
                </div>
                <div className="block">
                    <div className="line">Donate directly to help us improve the project:</div>
                    <div className="line direct">
                        <div className="crypto-icons">
                            <span className={"link "+(selected==="btc"?"selected":"")} onClick={() => {handleSelectCrypto('btc')}}><img src={btc} alt="BTC icon"/> BTC</span>
                            <span className={"link "+(selected==="eth"?"selected":"")} onClick={() => {handleSelectCrypto('eth')}}><img src={eth} alt="ETH icon"/> ETH</span>
                        </div>
                        <div className="wallet">
                        {selected === "btc" && (
                            <div>
                                <div className="qr-code btc"></div>
                                <div className="name">BTC wallet</div>
                                <div className="number btc">1FLQA64jhk5hYkYPcvA91GqD4q9ppAD8gQ</div>
                                <div className="email">You can email about transaction for record in hall of donators and my personal thanks: <a href="mailto:artem.pavlov@gmail.com">artem.pavlov@gmail.com</a></div>
                            </div>
                        )}
                        {selected === "eth" && (
                            <div>
                            <div className="qr-code eth"></div>
                            <div className="name">ETH wallet</div>
                            <div className="number eth">0x6c178487a701b8B28e43e743f564F0f4f7Fc4Ad3</div>
                            <div className="email">You can email about transaction for record in hall of donators and my personal thanks: <a href="mailto:artem.pavlov@gmail.com">artem.pavlov@gmail.com</a></div>
                        </div>
                        )}
                        </div>
                    </div>
                </div>
                <div className="block">And above all, use MTG Keeper and recommend it to anyone who may need it!</div>
                <div className="block thanks">A huge thanks to every one of you!</div>
            </div>
        </ModalBase>
    )
}

DonateModal.propTypes = {
    // Open state
    isOpen: PropTypes.bool.isRequired,
    // Callback on close dialog request
    onRequestClose: PropTypes.func,
}

export default DonateModal