export function defineCancelAPIObject(apiObject) {
    // an object that will contain a cancellation handler
    // associated to each API property name in the apiObject API object
    const cancelApiObject = {}

    // each property in the apiObject API layer object
    // is associated with a function that defines an API call

    // this loop iterates over each API property name
    Object.getOwnPropertyNames(apiObject).forEach((apiPropertyName) => {
        const cancellationControllerObject = {
            controller: undefined,
        }

        // associating the request cancellation handler with the API property name
        cancelApiObject[apiPropertyName] = {
            handleRequestCancellation: () => {
                // if the controller already exists,
                // canceling the request
                if (cancellationControllerObject.controller) {
                    // canceling the request and returning this custom message
                    cancellationControllerObject.controller.abort()
                }

                // generating a new controller
                // with the AbortController factory
                cancellationControllerObject.controller = new AbortController()

                return cancellationControllerObject.controller
            },
        }
    })

    return cancelApiObject
}

// Prepare unified error output (emulate structure of error from backend)
export const prepareErr = (error) => {
    if (error.response) // client received an error response (5xx, 4xx)
        return {data: {status: "failed", message: "Response error (" + error.message+ ")"}}
    else if (error.request) // client never received a response, or request never left
        return {data: {status: "failed", message: "Request error (" + error.message+ ")"}}
    else
        return {data: {status: "failed", message: "error.message"}}
}