import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './MenuItem.css'

export const menuItemTemplate = {
    danger: 'danger', // Red
    caution: 'caution', // Orange
    safe: 'safe', // Green
    disabled: 'disabled', // Gray
    hidden: 'hidden', // Not displayed
    spacer: 'spacer', // Empty spacer
}



/**
 * Menu item
 */
export class MenuItem extends Component {
    static propTypes = {
        // Item text
        text: PropTypes.string.isRequired,
        // Item template (see menuItemTemplate)
        template: PropTypes.string,
        // Callback on item click
        onClick: PropTypes.func.isRequired,
    }

    render() {
        return (
            <div className={"menu-item"+(this.props.template?" template-"+this.props.template:"")} onClick={this.handleClick}>
                <div className="text">{this.props.text}</div>
                <div className="icon"></div>
            </div>
        )
    }

    handleClick = (event) => {
        if (this.props.onClick && this.props.template !== menuItemTemplate.disabled)
            this.props.onClick(event)
    }
}




/**
 * Menu item acting as link
 */
export class MenuLinkItem extends Component {
    static propTypes = {
        // Title of link item
        text:       PropTypes.string.isRequired,
        // URL for link item
        href:       PropTypes.string.isRequired,
        // Target for link item
        target:     PropTypes.string,
        // Template for menu item
        template:   PropTypes.string,
    }

    render() {
        return(
            <div className={"menu-item"+(this.props.template?" template-"+this.props.template:"")}>
                <a className="text" href={this.props.href} target={this.props.target}>{this.props.text}</a>
                <div className="icon"></div>
            </div>
        )
    }
}

export default MenuItem