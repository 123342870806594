import { api } from './configs/axiosConfigs';
import { prepareErr, defineCancelAPIObject } from './configs/axiosUtils';
import { notifier } from '../libs/notifier';

export const deckAPI = {
    getDecks: async function(cancel = false) {
        const url = "/decks";
        const response = await api.post(url, {}, {
            signal: cancel ? cancelApiObject[this.getDecks.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                decks: response.data.decks,
            };
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return {
                decks: {},
            };
        }
    },

    addDeck: async function(name, note, cancel = false) {
        const url = "decks/add";
        const formData = new FormData();
        formData.append("name", name);
        formData.append("note", note);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.addDeck.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                deck: response.data.deck
            };
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return {};
        }
    },

    updateDeck: async function(deck_id, name, note, last_storage_id, dummies, cancel = false) {
        const url = "/decks/update";
        const formData = new FormData();
        formData.append("deck_id", deck_id);
        formData.append("name", name);
        formData.append("note", note);
        formData.append("last_storage_id", last_storage_id);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.updateDeck.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                deck: response.data.deck
            };
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

/*
    var deleteDeck = function(deck_id, callback) {
        let caller = notifier.getFunctionString('deleteDeck', [deck_id, 'callback'])
        $.ajax({
            type: 'POST',
            url: config.api_url+"/decks/delete",
            data:{
                deck_id: deck_id,
            },
            dataType: "json",
            success: function(result) {
                if (result.status === "success") {
                    // Remove deck_id from cache
                    delete decks[deck_id]
                    // Callback with result
                    if (callback) callback(true)
                    // Callback subscription
                    _callSubscription(dal.subs.decks)
                    return
                }
                else
                    notifier.notify(notifier.level.error, "Failed to delete MTG deck: "+result.message)
                if (callback) callback(false, result.message)
            },
            error: function(xhr, textStatus, errorThrown) {
                notifier.showAjaxError(xhr, textStatus, errorThrown, caller, 'deleting MTG deck')
                if (callback) callback(false)
            },
            timeout: 60000
        })
    }
    */

    getDummies: async function(deck_id, cancel = false) {
        const url = "/decks/dummies";
        const formData = new FormData();
        formData.append("deck_id", deck_id);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.getDummies.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return {
                dummies: response.data.dummies,
                samples: response.data.samples,
                cards: response.data.cards,
            };
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    addDummy: async function(deck_id, card_id, placeholder, sample_id, cancel = false) {
        const url = "/decks/dummies/add";
        const formData = new FormData();
        formData.append("deck_id", deck_id);
        formData.append("card_id", card_id);
        formData.append("placeholder", placeholder);
        formData.append("sample_id", sample_id);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.addDummy.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success") {
            return response.data.dummy;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    }

}

// Show formatted API error
const showAPIErr = (url, message) => {
    let errMsg = "Error in deckAPI call " + url +  " : " + message;
    notifier.logToConsole(notifier.level.error, errMsg, true);
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelAPIObject(deckAPI);