import React from 'react'
import { BigNumber } from 'bignumber.js'
import PropTypes from 'prop-types'

import { cardFinish, language } from '../../../hoc/MtgProvider'

import './SampleTile.css'


/**
 * Sample tile of card
 * @component
 */
export const SampleTile = (props) => {
    let re = new RegExp('/normal/', 'g')
    let small_img_url = props.sample.card.image_normal_uri.replace(re, '/small/')
    return (
        <div
            className={"sample-tile" + getSampleClasses()}
            onClick={handleClick}
        >
            <div className={"name "+(props.sample.card ? props.sample.card.rarity : "")}>
                {props.sample.card.name}
            </div>
            <div className="qty-block">
                <div className="qty">×&#8202;{props.sample.qty}</div>
                <div className="language">{getLanguage()}</div>
                {props.sample.finish === cardFinish.foil && (
                    <div className="finish foil" title="Card is foil">F</div>
                )}
                {props.sample.finish === cardFinish.etched && (
                    <div className="finish etched" title="Card has etched finish">E</div>
                )}

            </div>
            {getPriceBlock()}
            <img width="146" height="204" loading="lazy" src={small_img_url} alt={"Card: "+props.sample.card.name}/>
        </div>
    )

    function getSampleClasses() {
        let cls = ""
        if (props.upd_sample_id)
            cls += props.upd_sample_id === props.sample.id ? " updated" : ""
        if (props.active_sample_id)
            cls += props.active_sample_id === props.sample.id ? " active" : ""
        return cls
    }

    function getLanguage() {
        if (language[props.sample.language] !== undefined)
            return (<span className={"flag flag-"+props.sample.language}></span>)
        return (<span title="Unknown language">?</span>)
    }

    // Prepare price block
    function getPriceBlock() {
        if (!props.sample)
            return (<div className="price"></div>)
        if (!props.sample.card)
            return (<div className="price">?</div>)

        const price = (props.sample.finish === cardFinish.nonfoil ? BigNumber(props.sample.card.price_usd) :
                    (props.sample.finish === cardFinish.foil ? BigNumber(props.sample.card.price_usd_foil) :
                    (props.sample.finish === cardFinish.etched ? BigNumber(props.sample.card.price_usd_etched) : BigNumber("0"))))
        if (price.lt(props.cutoffPrice))
            return (<div className="price-block cutoff" title={"Value: $"+BigNumber(price).toFormat(2)}><span className="usd">&lt;&nbsp;$&#8202;</span>{BigNumber(props.cutoffPrice).toFormat(2)}</div>)

        //let total = price.times(props.sample.qty)

        return (
            <div className="price-block">
                <div className="total" title="Single card price">
                    <span className="usd">$&#8202;</span>{price.toFormat(2)}
                </div>
            </div>
        )
    }

    function handleClick(e) {
        props.onClick(props.sample)
    }
}

SampleTile.propTypes = {
    // Displayed sample
    sample:             PropTypes.object.isRequired,
    // ID of active sample
    active_sample_id:   PropTypes.number.isRequired,
    // ID of last updated sample
    upd_sample_id:      PropTypes.number.isRequired,
    // Cutoff price
    cutoffPrice:       PropTypes.number.isRequired,
    // Callback function on click
    onClick:            PropTypes.func.isRequired,
    // Callback function on select
    //onSelect:           PropTypes.func,
}

export default SampleTile