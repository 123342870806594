import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import './StorageInfo.css'

import { cardRarity } from '../../hoc/MtgProvider'



/**
 * Represent storage information
 * @component
 */
const StorageInfo = ({storage}) => {
    useEffect(() => {
        console.log('Initialization: StorageInfo')
    }, [])

    const getCountTotal = () => {
        if ('total_count' in storage) {
            let total = parseInt(storage.total_count[cardRarity.common]) +
                        parseInt(storage.total_count[cardRarity.uncommon]) +
                        parseInt(storage.total_count[cardRarity.rare]) +
                        parseInt(storage.total_count[cardRarity.mythic]);
            return total;
        }
        return '?';
    }
    const getCountCommon = () => {
        if ('total_count' in storage)
            return storage.total_count[cardRarity.common];
        return 0;
    }
    const getCountUncommon = () => {
        if ('total_count' in storage)
            return storage.total_count[cardRarity.uncommon];
        return 0;
    }
    const getCountRare = () => {
        if ('total_count' in storage)
            return storage.total_count[cardRarity.rare];
        return 0;
    }
    const getCountMythic = () => {
        if ('total_count' in storage)
            return storage.total_count[cardRarity.mythic];
        return 0;
    }
    const getTotalPrice = () => {
        if ('total_price_usd' in storage)
            return storage.total_price_usd;
        return 0;
    }
    const getNote = () => {
        if ('note' in storage)
            return storage.note;
        return '';
    }

    return (
        <div className="storage-info">
            <div className="cards-count">
                <span className="label">Cards:&nbsp;</span>
                <span className="total">{getCountTotal()}</span>&nbsp;
                (<span className="common">{getCountCommon()}</span>&nbsp;/&nbsp;
                <span className="uncommon">{getCountUncommon()}</span>&nbsp;/&nbsp;
                <span className="rare">{getCountRare()}</span>&nbsp;/&nbsp;
                <span className="mythic">{getCountMythic()}</span>)
            </div>
            <div className="cards-price"><span className="label">Value:&nbsp;</span><span className="usd">$&thinsp;</span>{getTotalPrice()}</div>
            <div className="note"><span className="label">Note:&nbsp;</span>{getNote()}</div>
        </div>
    )
}

StorageInfo.propTypes = {
    // Storage object
    storage: PropTypes.object.isRequired,
}

export default StorageInfo