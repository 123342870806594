import { scry_api } from './configs/axiosConfigs';
import { prepareErr, defineCancelAPIObject } from './configs/axiosUtils';
import { notifier } from '../libs/notifier';


export const scryAPI = {
    getSymbols: async function(cancel = false) {
        const url = "/symbology";

        const response = await scry_api.get(url, {
            signal: cancel ? cancelApiObject[this.getSymbols.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response.status === 200) {
            return response.data.data;
        } else {
            const msg = "HTTP Status " + response.status
                      + (response.statusText ? ": " + response.statusText : "");
            showAPIErr(url, msg);
            return null;
        }
    }
}

// Show formatted API error
const showAPIErr = (url, message) => {
    let errMsg = "Error in scryAPI call " + url +  " : " + message;
    notifier.logToConsole(notifier.level.error, errMsg, true);
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelAPIObject(scryAPI);