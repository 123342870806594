import React from 'react'
import PropTypes from 'prop-types'

import './ConfirmModal.css'

import ModalBase from '../ModalBase'

const DEFAULT_TITLE = "Confirmation"
const confirmModalStyles = {
    content: {
        top: '250px',
        width: '400px'
    }
}

/**
 * Confirmation dialog.
 * @component
 */
const ConfirmModal = (props) => {
    const cls = "modal-confirm" + (props.className ? " " + props.className : "");
    return (
        <ModalBase isOpen={props.isOpen}
                className={cls}
                onRequestClose={handleNo}
                style={confirmModalStyles}
        >
            <div className="modal-header">
                <div className="modal-title">{props.title ? props.title : DEFAULT_TITLE}</div>
                <div className="modal-close-x" onClick={handleNo}></div>
            </div>
            <div className="modal-content">
                {props.text}
                {props.children}
            </div>
            <div className="modal-control">
                <div className="buttons-wrapper">
                    <input
                        type="button"
                        className="mtgbutton"
                        title="Confirm"
                        onClick={handleYes}
                        autoFocus={props.defaultYes ? true : false}
                        value="Confirm"/>
                    <input
                        type="button"
                        className="mtgbutton"
                        title="Cancel"
                        onClick={handleNo}
                        autoFocus={props.defaultYes  ? false : true}
                        value="Cancel"/>
                </div>
            </div>
        </ModalBase>
    )

    function handleYes(event) {
        props.onResult(true)
    }
    function handleNo(event) {
        props.onResult(false)
    }
    //TODO: check if onResult valid in all places (plain bool result value)
}

ConfirmModal.propTypes = {
    // Open state of modal
    isOpen:     PropTypes.bool.isRequired,
    // Additional class name
    className:  PropTypes.string,
    // Title for modal (Default: "Confirmation")
    title:      PropTypes.string,
    // Confirmation text
    text:       PropTypes.string,
    // "Yes" button is in focus when the modal is opened
    defaultYes: PropTypes.bool,
    // Callback function on result confirmation
    onResult:   PropTypes.func.isRequired,
}

ConfirmModal.defaultProps = {
    defaultYes: false,
}

export default ConfirmModal