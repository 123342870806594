import React, { useEffect, useState } from 'react'
import { parsePrice } from '../../libs/helper'
import PropTypes from 'prop-types'

import './CardInfo.css'
import card_no_image from '../../assets/card_no_image.jpg'
import card_back from '../../assets/card_back.jpg'
import img_rotate from './rotate_w_100.png'
import { useMtg } from '../../hook/useMtg'



/**
 * Represent card information
 * @component
 */
const CardInfo = ({card, children}) => {
    const [rotate, setRotate] = useState(false);
    const [zoom, setZoom] = useState(false);

    const {sets} = useMtg();

    useEffect(() => {
        console.log('Initialization: CardInfo')
    }, [])

    useEffect(() => {
        // Reset rotate and zoom states
        setRotate(false);
        setZoom(false);
    }, [card])

    const getSetCode = () => {
        const set_id = card['set_id'];
        if (set_id in sets) {
            return sets[set_id].code.toUpperCase();
        }
        return '?';
    }
    const getSetName = () => {
        const set_id = card['set_id'];
        if (set_id in sets) {
            return sets[set_id].name;
        }
        return 'Unknown Set';
    }

    return (
        <div className="card-info">
            {card &&(
                <div className="card-info-wrapper">
                    <div className={"image"+(zoom ? " zoom" : "")}>
                        <div className={"flipper"+(card.image_normal_uri_2?" doublesided":"")+(rotate ? " flip" : "")}>
                            <div className="flipper-outer">
                                <div className="flipper-inner">
                                    <div className="front" onClick={handleZoom}>
                                    {(card.image_normal_uri && (
                                        <img draggable="false" src={card.image_normal_uri} alt={(card ? card.name + " " : "")+"(Front)"}/>
                                    )) || (
                                        <img draggable="false" src={card_no_image} alt={(card ? card.name + " ": "") + '(Front, No Image)'}/>
                                    )}
                                    </div>
                                    <div className="back" onClick={handleZoom}>
                                    {(card.image_normal_uri_2 && (
                                        <img draggable="false" src={card.image_normal_uri_2} alt={(card ? card.name + " " : "")+"(Back)"}/>
                                    )) || (
                                        <img draggable="false" src={card_back} alt={(card ? card.name + " ": "") + "(Back, No Image)"}/>
                                    )}
                                    </div>
                                </div>
                                <div className="flip-button" title="flip" onClick={handleRotate}>
                                    <img src={img_rotate} alt="flip"></img>
                                </div>
                            </div>
                        </div>
                        <div className="sub-image">
                            <div className="number" title={getSetName()}>{getSetCode()} {card['c_number']}</div>
                            <div className={"rarity " + card['rarity']}>{card['rarity']}</div>
                        </div>
                    </div>
                    <div className="info">
                        <div className="name">{card ? card.name : ""}</div>
                        <div className="properties">
                            <div className="h-block">
                                <div className={"finish " + (card['nonfoil']?"yes":"no")}>
                                    <span className="yes" title="Available in non-foil version">☑️</span>
                                    <span className="no" title="Doesn't available in non-foil version">⬜</span> Non Foil
                                </div>
                                <div className={"finish " + (card['foil']?"yes":"no")}>
                                    <span className="yes" title="Available in foil version">☑️</span>
                                    <span className="no" title="Doesn't available in foil version">⬜</span> Foil
                                </div>
                                {card['etched'] && (
                                <div className={"finish " + (card['etched']?"yes":"no")}>
                                    <span className="yes" title="Available in etched version">☑️</span> Etched
                                </div>
                                )}
                            </div>
                            <div className="h-block">
                                <div className="price">{card['nonfoil'] && parsePrice(card.price_usd)} </div>
                                <div className="price">{card['foil'] && parsePrice(card.price_usd_foil)} </div>
                                {card['etched'] && (
                                <div className="price">{card['etched'] && parsePrice(card.price_usd_etched)} </div>
                                )}
                            </div>
                            <div className="spacer"></div>
                        </div>
                        <div className="spacer"></div>
                        <div className="extended">
                            {children}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

    function handleRotate() {
        setRotate(!rotate);
    }

    function handleZoom() {
        setZoom(!zoom);
    }
}

CardInfo.propTypes = {
    // Card object
    card: PropTypes.object.isRequired,
}

export default CardInfo