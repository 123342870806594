import React, { useEffect, useState } from 'react'
import { BigNumber } from 'bignumber.js'
import PropTypes from 'prop-types'

import './SamplesList.css'

import { useMtg } from '../../hook/useMtg'
import { SimpleSelectLabeled } from '../SimpleComponents/SimpleSelect'
import OrderingSwitch, { orderType } from '../SimpleComponents/OrderingSwitch'
import SimpleCheckbox from '../SimpleComponents/SimpleCheckbox'
import SamplesListContent, { sortType } from './SampleListContent';
import { cardFinish } from '../../hoc/MtgProvider'


export const viewModeList = {
    rows: {
        value: "rows",
        description: "Show all cards as distinct rows",
        showAsPacks: false,
        showAsTiles: false,
    },
    tiles: {
        value: "tiles",
        description: "Show cards as thumbnails",
        showAsPacks: false,
        showAsTiles: true,
    },
    combined: {
        value: "combined",
        description: "Show all cards as rows and combine cards with the same collector number into packs",
        showAsPacks: true,
        showAsTiles: false,
    }
}



/**
 * List of card instances (samples) with controls
 * @component
 */
const SamplesList = (props) => {
    const [sorting, setSorting] = useState(props.default_sort)
    const [ordering, setOrdering] = useState(orderType.ascending);
    const [viewMode, setViewMode] = useState(props.viewMode);
    const [stat, setStat] = useState({})
    const [value, setValue] = useState(BigNumber("0"));
    const [valueTotal, setValueTotal] = useState(BigNumber("0"));
    const {cutoffPrice} = useMtg();

    useEffect(() => {
        console.log('Initialization: SamplesList');
    }, [])

    useEffect(() => {
        setSorting(props.default_sort);
    }, [props.default_sort])

    useEffect(() => {
        setViewMode(props.viewMode);
    }, [props.viewMode])

    useEffect(() => {
        const statUpd = {
            total: 0,
            c: 0,
            uc: 0,
            r: 0,
            m: 0,
            f: 0,
            e: 0,
        }
        for (let key of Object.keys(props.samples)) {
            statUpd.total += props.samples[key].qty;
            statUpd.c += (props.samples[key]?.card?.rarity === "common" ? props.samples[key].qty : 0);
            statUpd.uc+= (props.samples[key]?.card?.rarity === "uncommon" ? props.samples[key].qty : 0);
            statUpd.r += (props.samples[key]?.card?.rarity === "rare" ? props.samples[key].qty : 0);
            statUpd.m += (props.samples[key]?.card?.rarity === "mythic" ? props.samples[key].qty : 0);
            statUpd.f += (props.samples[key].finish === cardFinish.foil ? props.samples[key].qty : 0);
            statUpd.e += (props.samples[key].finish === cardFinish.etched ? props.samples[key].qty : 0);
        }
        setStat(statUpd)
    }, [props.samples])

    useEffect(() => {
        let valueUpd = BigNumber("0");
        let valueTotalUpd = BigNumber("0");
        for (let key of Object.keys(props.samples)) {
            const sample = props.samples[key];
            if (sample?.card) {
                const price = sample.finish === cardFinish.nonfoil ? BigNumber(sample.card.price_usd) :
                             (sample.finish === cardFinish.foil ? BigNumber(sample.card.price_usd_foil) :
                             (sample.finish === cardFinish.etched ? BigNumber(sample.card.price_usd_etched) :
                              BigNumber("0")));
                if (price.gte(cutoffPrice)) {
                    valueUpd = valueUpd.plus(price.times(sample.qty));
                }
                valueTotalUpd = valueTotalUpd.plus(price.times(sample.qty));
            }
        }
        setValue(valueUpd);
        setValueTotal(valueTotalUpd);
    }, [props.samples, cutoffPrice])


    const getTotalCards = () => {
        return (
            <span className="value">
                <span className="total" title="Total cards">{stat.total}&thinsp;:&thinsp;</span>
                <span className="detailed">
                    <span className="common" title="Common cards">{stat.c}&thinsp;</span>
                    /<span className="uncommon" title="Uncommon cards">&thinsp;{stat.uc}&thinsp;</span>
                    /<span className="rare" title="Rare cards">&thinsp;{stat.r}&thinsp;</span>
                    /<span className="mythic" title="Mythic cards">&thinsp;{stat.m}&thinsp;</span>
                    {(stat.f > 0 || stat.e > 0) && (<>&nbsp;[</>)}
                    {stat.f > 0 && (
                    <span className="foil" title="Foil cards">{stat.f}&thinsp;</span>
                    )}
                    {stat.e > 0 && (
                    <>/<span className="etched" title="Etched cards">&thinsp;{stat.e}</span></>
                    )}
                    {(stat.f > 0 || stat.e > 0) && (<>]</>)}
                </span>
            </span>
        )
    }


    const getSelectAllState = () => {
        if (props.selected_ids.size >= Object.keys(props.samples).length)
            return true;
        return false;
    }


    //console.log('SamplesList cutoff price:', cutoffPrice)
    return (
        <div className="sample-list">
            {props.show_subhead && (
            <div className="subhead">
                {props.show_checkbox && (
                <SimpleCheckbox
                    state={getSelectAllState()}
                    title="Select or Deselect all"
                    className="select-all"
                    onClick={handleSelectAll}
                />
                )}
                {props.show_change_mode && (
                <div className={"view-mode "+viewMode.value} title={viewMode.description} onClick={handleSwitchViewMode}></div>
                )}
                <div className="total_cards"><span className="label"># Cards:</span> {getTotalCards()}</div>
                <div className="total_value">
                    <span className="label">Total value:</span>&nbsp;
                    <span className="clipped" title={"Total value of cards with price >= $"+cutoffPrice}><span className="usd">$&#8202;</span><span className="value">{value.toString()}</span></span>&nbsp;
                    <span className="full" title="Total value of all cards"><span className="usd">$&#8202;</span><span className="value">{valueTotal.toString()}</span></span>
                </div>
                <div className="spacer"></div>
                <div className="sorting">
                    <SimpleSelectLabeled
                        value={sorting}
                        label="Sort:"
                        onChange={handleSortChange}
                    >
                        <option value={sortType.price}>Value</option>
                        <option value={sortType.rarity}>Rarity</option>
                        <option value={sortType.collectorNumber}>Collector Number</option>
                        <option value={sortType.dateChanged}>Date changed</option>
                        <option value={sortType.name}>Card Name</option>
                    </SimpleSelectLabeled>
                    <OrderingSwitch
                        state={ordering}
                        onClick={handleSwitchOrdering}
                    />
                </div>
            </div>
            )}
            <SamplesListContent
                samples={props.samples}
                is_owned={props.is_owned}
                selected_ids={props.selected_ids}
                show_storage={props.show_storage}
                show_checkbox={props.show_checkbox}
                show_as_packs={viewMode.showAsPacks}
                show_as_tiles={viewMode.showAsTiles}
                sorting={sorting}
                ordering={ordering}
                onActivate={props.onActivate}
                onSelect={props.onSelect}
            />
        </div>
    )

    function handleSelectAll() {
        //console.log('handleClickSelectAll');
        const selected_all = getSelectAllState();
        const selected_none = props.selected_ids.size > 0 ? false : true;
        // Deselect
        if (selected_all || !selected_none) {
            props.onSelect(null, false, true);
            //console.log('deselect');
            return;
        }
        // Select all
        if (selected_none) {
            props.onSelect(null, true, false);
            //console.log('select all');
            return;
        }
    }

    function handleSortChange(event) {
        setSorting(event.target.value);
    }

    // Switch sorting ordering
    function handleSwitchOrdering() {
        const orderingUpd = (ordering === orderType.ascending ? orderType.descending : orderType.ascending);
        setOrdering(orderingUpd);
    }

    // Switch view mode
    function handleSwitchViewMode() {
        switch (viewMode.value) {
            case viewModeList.combined.value:
                setViewMode(viewModeList.rows);
                break
            case viewModeList.rows.value:
                setViewMode(viewModeList.tiles);
                break
            case viewModeList.tiles.value:
            default:
                setViewMode(viewModeList.combined);
        }
    }
}

SamplesList.propTypes = {
    // Associative array (Object) of samples
    samples:            PropTypes.objectOf(PropTypes.object).isRequired,
    // Is this storage owned by current user
    is_owned:           PropTypes.bool.isRequired,
    // Set of selected samples IDs
    selected_ids:       PropTypes.instanceOf(Set),
    // View mode for the list
    viewMode:           PropTypes.shape({
                            value: PropTypes.string,
                            description: PropTypes.string,
                            showAsPacks: PropTypes.bool,
                            showAsTiles: PropTypes.bool
                        }),
    // Flag that storage should be displayed for each sample
    show_storage:       PropTypes.bool,
    // Flag that checkbox should be displayed for each sample
    show_checkbox:      PropTypes.bool,
    // Flag that change mode element should be showed
    show_change_mode:   PropTypes.bool,
    // Show subhead
    show_subhead:       PropTypes.bool,
    // Default sorting
    default_sort:       PropTypes.string,
    // Callback on activate (select as active) sample
    onActivate:         PropTypes.func.isRequired,
    // Callback on select (with checkbox) sample
    onSelect:           PropTypes.func,
}

SamplesList.defaultProps = {
    selected_ids: new Set(),
    viewMode: viewModeList.combined,
    show_storage: true,
    show_checkbox: false,
    show_change_mode: true,
    show_subhead: true,
    default_sort: sortType.collectorNumber,
}

export default SamplesList