import { useCallback } from "react";
import { createContext, useState, useEffect } from "react";

import { cardAPI } from "../apis/cardAPI";
import { useMtg } from "../hook/useMtg";

export const CardContext = createContext(null);

export const CardProvider = ({children}) => {
    const [cards, setCards] = useState([]);
    const [loadingCards, setLoadingCards] = useState(false);
    const [filter, setFilter] = useState({
        set_code: [],
        name: "",
    });

    const {sets, updCardRarity} = useMtg();

    useEffect(() => {
        console.log('Initialization: CardProvider');
    }, [])

    const applyFilter = useCallback(
        (filterUpd) => {
            setFilter(filterUpd);
            setLoadingCards(true);
            cardAPI.getCards(filterUpd).then((resp) => {
                if (resp) {
                    let cardsUpd = [];
                    for (let i = 0; i < resp.cards.length; i++) {
                        cardsUpd.push(updCardRarity(resp.cards[i]));
                    }
                    setCards(cardsUpd);
                } else {
                    setCards([]);
                }
                setLoadingCards(false);
            });
        },
        [updCardRarity]
    )

useEffect(()  =>{
    console.log('CardProvider: sets changed');
}, [sets])
useEffect(()  =>{
    console.log('CardProvider: applyFilter changed');
}, [applyFilter])
useEffect(()  =>{
    console.log('CardProvider: updCardRarity changed');
}, [updCardRarity])


    useEffect(() => {
        if (Object.keys(sets).length) {
            // Select First set active on load
            const sortedSets = Object.keys(sets)
                .sort((a, b) => sets[a].released_at < sets[b].released_at ? 1 : -1) // Sort: Newest first
                .filter(key => sets[key].set_type === 'core' || sets[key].set_type === 'expansion'); // Only core and expansion sets
            const firstKey = sortedSets.slice(0, 1)[0];
            const firstCode = sets[firstKey].code;
            let filterUpd = {};
            filterUpd.set_code = [firstCode];
            filterUpd.name = "";
//console.log('filterUpd', filterUpd);
            applyFilter(filterUpd);
        }
    }, [sets, applyFilter])

    // Search card by collector number
    function searchCard(setCode, collectorNumber, onResult) {
        cardAPI.searchCard(setCode, collectorNumber).then((resp) => {
            if (resp.card) {
                onResult(true, updCardRarity(resp.card));
            } else {
                onResult(false, resp.message);
            }
        })
    }

    const value = {
        cards,
        loadingCards,
        filter,
        setFilter, // set without reload card
        applyFilter, // set with cards reload
        searchCard,
    }

    return (
        <CardContext.Provider value={value}>
            {children}
        </CardContext.Provider>
    )
}

