import React from 'react'
import PropTypes from 'prop-types'

import './SimpleTextarea.css'

/**
 * Simple textarea component
 * @component
 */
export const SimpleTextarea = (props) => {
    const cls = "simple-textarea" +
                (props.limit ? " limited" : "") +
                (props.className ? " " + props.className : "");
    return(
        <div className={cls}>
            <textarea
                placeholder={props.placeholder}
                value={props.value}
                title={props.title}
                onChange={props.onChange}
                onKeyDown={props.onKeyDown}
                tabIndex={props.tabIndex}
            />
            {props.limit > 0 && (
                <label className="limit" title="Max length">{props.value.length}&thinsp;/&thinsp;{props.limit}</label>
            )}
        </div>
    )
}

SimpleTextarea.propTypes = {
    // Placeholder for unfocused & empty input
    placeholder:    PropTypes.string,
    // Value of input
    value:          PropTypes.string.isRequired,
    // Title html attribute for component
    title:          PropTypes.string,
    // Custom class
    className:      PropTypes.string,
    // Callback function on change
    onChange:       PropTypes.func.isRequired,
    // Callback function key down
    onKeyDown:      PropTypes.func,
    // Custom tab order of an element
    tabIndex:       PropTypes.number,
    // Length limit
    limit:          PropTypes.number,
}

export default SimpleTextarea