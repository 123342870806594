import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import './App.css';

import { useAuth } from './hook/useAuth'
import { notifier } from './libs/notifier'
import DisconectedPage from './pages/DisconectedPage/DisconectedPage'
import AllCardsPage from './pages/AllCardsPage/AllCardsPage'
import CollectionsPage from './pages/CollectionsPage/CollectionsPage'
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ContentPage from './pages/ContentPage/ContentPage';
import DecksPage from './pages/DecksPage/DecksPage';
import { StorageProvider } from './hoc/StorageProvider';
import { SampleProvider } from './hoc/SampleProvider';
import { CardProvider } from './hoc/CardProvider';
import { DeckProvider } from './hoc/DeckProvider';

//var NotificationSystem = require('react-notification-system')


/**
 * MTG Keeper application
 * @component
 */
const App = (props) => {
    const {connected} = useAuth();

    useEffect(() => {
        console.log("Initialization: App");

        // Set the notification handler in this application
        notifier.setNotificationHandler(addNotification)

        // TODO: add server logging
        //notifier.setServerLogHandler

    }, []);

    // let notification_style = {
    //     Containers: {
    //         bl: {
    //             //bottom: '20px',
    //         }
    //     }
    // }


    if (!connected) {
        return (
            <DisconectedPage
                title="Server connection error"
            />
        )
    } else {
        return (
            <div className="mtgcollection-app">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ContentPage
                                    title="Collection Management Service"
                                />
                            }
                        />
                        {["/collection", "/collection/:storage_id", "/collection/:storage_id/:sample_id"].map(
                            (path, idx) => (
                                <Route
                                    key={idx}
                                    path={path}
                                    element={
                                        <StorageProvider>
                                            <SampleProvider>
                                                <CollectionsPage
                                                    title="Collection"
                                                    layout="default"
                                                    />
                                            </SampleProvider>
                                        </StorageProvider>
                                    }
                                />
                            )
                        )}
                        <Route
                            path="/all"
                            element={
                                <StorageProvider>
                                    <SampleProvider>
                                        <CardProvider>
                                            <AllCardsPage
                                                title="Cards Browser"
                                                layout="default"
                                            />
                                        </CardProvider>
                                    </SampleProvider>
                                </StorageProvider>
                            }
                        />
                        {["/decks", "/decks/:deck_id"].map(
                            (path, idx) => (
                                <Route
                                    //exact={true}
                                    //path={["/decks/:deck_id", "/decks"]}
                                    key={idx}
                                    path={path}
                                    // render={(props)=>(
                                    //     <DecksPage
                                    //         title="Decks"
                                    //         layout="default"
                                    //         authenticated={authenticated}
                                    //         onSignin={signin}
                                    //         {...props}
                                    //     />
                                    // )}
                                    element={
                                        <DeckProvider>
                                            <StorageProvider>
                                                <SampleProvider>
                                                    <DecksPage
                                                        title="Decks"
                                                        layout="default"
                                                    />
                                                </SampleProvider>
                                            </StorageProvider>
                                        </DeckProvider>
                                    }
                                />
                            )
                        )}
                        <Route
                            path="*"
                            element={
                                <NotFoundPage
                                    title="Not Found"
                                    layout="default"
                                />
                            }
                        />
                    </Routes>

                {/* <NotificationSystem
                    ref={this.notificationSystemRef}
                    style={notification_style}
                /> */}
            </div>
        )
    }

    // Add Notification
    function addNotification(level, message, title) {
        let level_str = 'info';
        let auto_dismiss = 7; // Autohide time in seconds
        switch (level) {
            case notifier.level.debug:
                level_str = 'info'
                break
            case notifier.level.info:
                level_str = 'info'
                break
            case notifier.level.success:
                level_str = 'success'
                break
            case notifier.level.warning:
                level_str = 'warning'
                auto_dismiss = 0
                break
            case notifier.level.error:
                level_str = 'error'
                auto_dismiss = 0
                break
            case notifier.level.critical:
                level_str = 'error'
                auto_dismiss = 0
                break
            default:
                level_str = 'info'
                break
        }
        /*
        // Transform \n to <br>
        message = message.replace("\n", "<br>")

        if (this.notificationSystemRef.current) {
            this.notificationSystemRef.current.addNotification({
                title: title,
                message: message,
                level: level_str,
                position: 'bl',
                autoDismiss: auto_dismiss,
            })
        }*/
    }
}

App.propTypes = {
}

export default App;
