import React from 'react'
import PropTypes from 'prop-types'

import './SignInModal.css'
import ModalBase from '../ModalBase'


const signInModalStyles = {
    content: {
        width: '600px',
        maxHeight: '100%',
        height: '140px',
    }
}



/**
 * Sign In and Sign Up modal
 * @component
 */


// TODO: Make refactoring into a functional component
// TODO: Add PropTypes
// class SignInModal extends Component {
//     constructor(props){
//         super(props)
//         this.state = {
//             isOpen: false,
//         }
//     }

//     render() {
//         return (
//             <ModalBase
//                 isOpen={this.state.isOpen}
//                 className="modal-signin"
//                 onRequestClose={this.close}
//                 style={signInModalStyles}
//             >
//                 <div className="panel-left">
//                     <div className="content-wrapper">
//                         <div className="head">Sign in with…</div>
//                         <div className="content">
//                             <a className="google-signin" href="/login/google">Sign in with Google</a>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="panel-right">
//                     <div className="content-wrapper">
//                         <div className="head">Sign in using account</div>
//                         <div className="content">not supported yet…</div>
//                     </div>
//                 </div>
//             </ModalBase>
//         )
//     }

//     // Open modal
//     open = () => {
//         console.log('open')
//         this.setState({isOpen: true})
//     }

//     // Close modal
//     close = () => {
//         this.setState({isOpen: false})
//         this.reset()
//     }

//     // Reset input to default
//     reset = () => {
//     }
// }

const SignInModal = (props) => {
    return (
        <ModalBase
            isOpen={props.isOpen}
            className="modal-signin"
            onRequestClose={props.onRequestClose}
            style={signInModalStyles}
        >
            <div className="panel-left">
                <div className="content-wrapper">
                    <div className="head">Sign in with…</div>
                    <div className="content">
                        <a className="google-signin" href="/login/google">Sign in with Google</a>
                    </div>
                </div>
            </div>
            <div className="panel-right">
                <div className="content-wrapper">
                    <div className="head">Sign in using account</div>
                    <div className="content">not supported yet…</div>
                </div>
            </div>
        </ModalBase>
    )
}

SignInModal.propTypes = {
    // Open state
    isOpen: PropTypes.bool,
    // Callback on close dialog request
    onRequestClose: PropTypes.func,
}

export default SignInModal