import { useEffect } from 'react'
import PropTypes from 'prop-types'

import './RawLayout.css'



/**
 * Raw page layout
 * @component
 */
const RawLayout = (props) => {
    useEffect(() => {
        document.title = props.title
    }, [props.title])

    return (
        <div className="raw-layout">
            <div className="layout-workspace">
                {props.children}
            </div>
        </div>
    )
}

RawLayout.propTypes = {
    // Page title for the browser tab
    title:  PropTypes.string.isRequired,
}

export default RawLayout