import React from 'react'
import PropTypes from 'prop-types'

import './Spinner.css'



/**
 * Simple spinner representing loading status
 * @component
 */
export const Spinner = (props) => {
    const cls = "spinner " + (props.className ? " " + props.className : "")
    return(
        <div className={cls}></div>
    )
}

Spinner.propTypes = {
    // Additional class
    className:  PropTypes.string,
}

export default Spinner