import { useEffect } from 'react'
import PropTypes from 'prop-types'

import './DefaultLayout.css'

import TopMenu from '../elements/TopMenu/TopMenu'
import Footer from '../elements/Footer/Footer'



/**
 * Default page layout
 * @component
 */
const DefaultLayout = (props) => {

    useEffect(() => {
        document.title = props.title + " | MTG Keeper"
    }, [props.title])

    return (
        <div className="default-layout">
            <TopMenu />
            <div className="layout-workspace">
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

DefaultLayout.propTypes = {
    // Page title for the browser tab
    title:                  PropTypes.string.isRequired,
}

export default DefaultLayout