import React from 'react'
import PropTypes from 'prop-types'

import './SamplePackRow.css'
import { useStorage } from '../../../hook/useStorage'



/**
 * Sample pack row for list component
 * Pack is a summary of similar card samples
 * @component
 */
export const SamplePackRow = (props) => {
    const {
        getStorageTitleShort,
        getStorageTitleFull,
    } = useStorage();

    return (
        <div className={"sample-list-pack-row" + getSamplePackClasses(props)}>
            <div
                className="summary"
                onClick={handleClick}
            >
                {(props.show_storage && props.is_owned) && (
                <div className="storage_code" title={getStorageTitleFull(props.pack.storage_id)}>{getStorageTitleShort(props.pack.storage_id)}</div>
                )}
                <div className="card_number"
                    title={props.pack.set ? props.pack.set.name : "?"}
                >
                    <span className="set_code">{props.pack.set ? props.pack.set.code : "?"}</span>&nbsp;
                    <span className="c_number">{props.pack.card ? props.pack.card.c_number : "?"}</span>&nbsp;
                </div>
                <div className="language"><span className="flag"></span></div>
                <div className="unfold-state"><div className="state"></div></div>
                <div className="qty">{props.pack.count}&#8202;×</div>
                <div className={"name " + props.pack.card.rarity}>{props.pack.card.name}</div>
            </div>
            {props.children}
        </div>
    )

    function handleClick(e) {
        props.onClick(props.pack.card.id)
    }

    function getSamplePackClasses(props) {
        return props.unfold ? " unfold" : ""
    }
}

SamplePackRow.propTypes = {
    // Pack of samples
    pack:           PropTypes.object.isRequired,
    // Is pack unfolded
    unfold:         PropTypes.bool.isRequired,
    // Flag that the storage should be displayed
    show_storage:   PropTypes.bool,
    // Callback function on click
    onClick:        PropTypes.func.isRequired,
}

SamplePackRow.defaultProps = {
    show_storage: true,
}


export default SamplePackRow