import React, { useEffect, useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import './DeckConstructorPanel.css'

import { useDeck } from '../../../hook/useDeck'
import { useSample } from '../../../hook/useSample'
import { useStorage } from '../../../hook/useStorage'
import WorkspacePanel, { WorkspacePanelHead } from '../../../components/WorkspacePanel/WorkspacePanel'
import SamplesList, { viewModeList } from '../../../components/SamplesList/SamplesList'
import SimpleSelect from '../../../components/SimpleComponents/SimpleSelect'
import CmcPlaceholder from './CmcPlaceholder'
import CmcSpacer from './CmcSpacer'
import { ItemTypeDelimiter } from '../../../utils/items'


//const START_PLACEHOLDERS_COUNT = 5



/**
 * Construction Panel component
 * @component
 */
const DeckConstructorPanel = (props) => {
    const {activeDeck, updateDeck, dummies} = useDeck();
    const {samples} = useSample();
    const {storages, activeStorage, selectStorage} = useStorage();

    const [placeholders, setPlaceholders] = useState({});
    //const [isChanged, setIsChanged] = useState(false);
    const [dummyId, setDummyId] = useState(-1); // ID for newly added dummy (negatives & decrement)


    useEffect(() => {
        console.log('Initialization: DeckConstructorPanel');
    }, [])

    useEffect(() => {
        if (activeDeck) {
            // Select active storage
            if (activeDeck.last_storage_id && activeDeck.last_storage_id in storages) {
                selectStorage(storages[activeDeck.last_storage_id]);
            } else {
                if (Object.values(storages).length) {
                    selectStorage(Object.values(storages)[0]);
                } else {
                    selectStorage(null);
                }
            }
            console.log('DeckConstructorPanel mounted with deck #', activeDeck.id);
        }
    }, [activeDeck, storages, selectStorage])

    useEffect(() => {
        let placeholdersUpd = {};
        for (let key in dummies) {
            const dummy = dummies[key];
            if (!(dummy.card.cmc in placeholdersUpd)) {
                placeholdersUpd[dummy.card.cmc] = {
                    'id': 'deck-cmc-' + dummy.card.cmc,
                    'dummies': [],
                }
            }
            placeholdersUpd[dummy.card.cmc].dummies.push(dummy);
        }
        //placeholdersUpd['3'] = {'id': 'deck-cmc-'+7, 'dummies': []};
        setPlaceholders(placeholdersUpd);
        //console.log('DeckConstructorPanel set dummies...');
    }, [dummies])

    return (
        <WorkspacePanel className="deck-constructor noselect">
            <WorkspacePanelHead title="Constructor">
            </WorkspacePanelHead>
            <DragDropContext
                onDragEnd={handleDragEnd}
            >
                <div className="storage">
                    <div className="storage-title">
                        <SimpleSelect
                            value={(activeStorage ? "" + activeStorage.id : "")}
                            onChange={handleSelectStorage}
                        >
                            {Object.keys(storages).map((key, index) => (
                                <option
                                    key={storages[key].id}
                                    value={storages[key].id}>{storages[key].name}</option>
                            ))}
                        </SimpleSelect>
                    </div>
                    <SamplesList
                        samples={samples}
                        is_owned={true}
                        show_as_packs={false}
                        show_as_tiles={true}
                        viewMode={viewModeList.tiles}
                        show_change_mode={false}
                        onActivate={()=>{}}
                    />
                </div>
                <div className="deck-summary">
                    <div className="deck-name">Name: {activeDeck.name}</div>
                    <div className="cmc-curve">TODO</div>
                    <div className="cards">
                        asd
                    </div>
                    <button onClick={processSaveDeck}>Save</button>
                </div>
                <div className="deck-content scrollable">
                    <div className="placeholders-wrapper">
                        {Object.keys(placeholders).map((key, index) => (
                            <React.Fragment key={key}>
                                <CmcSpacer
                                    droppableId={'deck-spc-'+key}
                                />
                                <CmcPlaceholder
                                    droppableId={'deck-cmc-'+key}
                                    dummies={placeholders[key].dummies}
                                />
                            </React.Fragment>
                        ))}
                        <CmcSpacer
                            droppableId={'deck-spc-last'}
                        />
                    </div>
                </div>
            </DragDropContext>
        </WorkspacePanel>
    )


    function handleSelectStorage(event) {
        const storageId = event.target.value;
        if (storageId in storages) {
            if (activeDeck) {
                updateDeck(activeDeck, {
                    last_storage_id: storageId
                });
                //selectStorage(storages[storageId]);
            }
        }
    }

    // Add sample in the deck
    function handleAddToDeck(sample) {
        let dummy = {
            'deck_id': activeDeck.id,
            'sample_id': sample.id,
            'count': sample.qyty,
        }
        // let dummies_upd = this.state.dummies.p
        let a ={}

        console.log('Sample added to deck', sample)
    }

    function handleDragEnd(result) {
        const { source, destination, draggableId: draggableIdStr } = result;
        // Parse draggable type & ID
        const draggableType = draggableIdStr.split(ItemTypeDelimiter)[0];
        const draggableId = parseInt(draggableIdStr.split(ItemTypeDelimiter)[1]);

        console.log('result: ', result);
        console.log('source:', source);
        console.log('dest:', destination);
        console.log('draggableType: ', draggableType);
        console.log('draggableId:', draggableId);

        // * Dropped outside the list
        if (!destination) { return }
        // * Dropped to same droppable
        if (source.droppableId === destination.droppableId) { return }

        // * Dropped from deck to collection (remove dummy from deck)
        if (destination.droppableId === "collection") {
            if (draggableId > 0) { // Remove existing dummy from deck
                console.log("Remove existing dummy #", draggableId);

            } else { // Remove newly added dummy
                console.log("Remove new dummy #", draggableId);
                const sourcePlaceholderIndex = source.droppableId.substring("deck-cmc-".length);
                let placeholdersUpd = Object.assign({}, placeholders)
                placeholdersUpd[sourcePlaceholderIndex].dummies = placeholdersUpd[sourcePlaceholderIndex].dummies.filter((obj) => {return obj.id !== draggableId});
                setPlaceholders(placeholdersUpd);
            }
            console.log(placeholders);
            return
        }

        // * Dropped on spacer
        if (destination.droppableId.startsWith("deck-spc-")) {
            let plc_index = destination.droppableId.substring("deck-spc-".length);
            console.log("Add placeholder before #", plc_index);
            return
        }

        // * Dropped from placeholder to placeholder
        if (source.droppableId.startsWith("deck-cmc-") && destination.droppableId.startsWith("deck-cmc-")) {
            // Get placeholder indexes
            const sourcePlaceholderIndex = source.droppableId.substring("deck-cmc-".length);
            const destinationPlaceholderIndex = destination.droppableId.substring("deck-cmc-".length);
            //console.log(sourcePlaceholderIndex, ' -> ', destinationPlaceholderIndex);
            //const dummyId = parseInt(draggableId);
            const dummy_arr = placeholders[sourcePlaceholderIndex].dummies.filter((obj) => {return obj.id === draggableId});
            //console.log('dummy_arr', dummy_arr);

            if (dummy_arr.length) {
                let placeholdersUpd = Object.assign({}, placeholders)
                //console.log(placeholdersUpd[sourcePlaceholderIndex]);
                placeholdersUpd[sourcePlaceholderIndex].dummies = placeholdersUpd[sourcePlaceholderIndex].dummies.filter((obj) => {return obj.id !== draggableId});
                placeholdersUpd[destinationPlaceholderIndex].dummies.push(dummy_arr[0]);
                if (placeholdersUpd[sourcePlaceholderIndex].dummies.length === 0) {
                    // Delete placeholder if contains no dummies after operation
                    delete placeholdersUpd[sourcePlaceholderIndex];
                }
                setPlaceholders(placeholdersUpd);
                //console.log(placeholdersUpd);
            }
            return
        }

        // * Dropped on placeholder
        // Prepare Sample
        const sample = samples[draggableId];

        // Prepare target Placeholder
        //const destClone = Object.values(placeholders)
        //const destPlaceholder = destClone[destination.index]

        // Update placeholders
        //console.log(placeholders)
        let placeholdersUpd = {}
        for (let plc in placeholders) {
            if (destination.droppableId === placeholders[plc].id) {
                let placeholderUpd = {
                    'id': placeholders[plc].id,
                    'dummies': placeholders[plc].dummies,
                }
                let new_dummy = {
                    id: dummyId,
                    deck_id: activeDeck.id,
                    //placeholder: 2, //TODO: remove
                    sample_id: sample.id,
                    sample: sample,
                    card: sample.card,
                }
                placeholderUpd.dummies.push(new_dummy)
                placeholdersUpd[plc] = placeholderUpd
            } else {
                placeholdersUpd[plc] = placeholders[plc]
            }
        }
        setDummyId(dummyId-1);
        setPlaceholders(placeholdersUpd);

        console.log('!', destination.droppableId)
        console.log("upd:", placeholdersUpd)
    }

    function processSaveDeck() {
        console.log('processSaveDeck')
    }
}

DeckConstructorPanel.propTypes = {
    // Active storage object
    //activeDeck:        PropTypesCustom.object_or_null_required,
}

export default DeckConstructorPanel