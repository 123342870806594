import { api } from './configs/axiosConfigs';
import { prepareErr, defineCancelAPIObject } from './configs/axiosUtils';
import { notifier } from '../libs/notifier';


export const storageAPI = {
    get: async function(storageId, cancel = false) {
        const url = "/storage";
        const formData = new FormData();
        formData.append("storage_id", storageId);
        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success")
            return response.data.storage;
        else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    getAll: async function(cancel = false) {
        const url = "/storages";
        const response = await api.post(url, {}, {
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        }).catch((error) => {
            return prepareErr(error);
        })

        if (response?.data?.status === "success")
            return response.data.storages;
        else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return [];
        }
    },

    add: async function(name, shortname, note, cancel = false) {
        const url = "/storages/add";
        const formData = new FormData();
        formData.append("name", name);
        formData.append("shortname", shortname);
        formData.append("note", note);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.add.name].handleRequestCancellation().signal : undefined
        }).catch((error) => {
            return prepareErr(error);
        })
        if (response?.data?.status === "success") {
            return response.data.storage;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    update: async function(storage, name, shortname, note, hidden, public_, cancel = false) {
        const url = "/storages/update";
        const formData = new FormData();
        formData.append("storage_id", storage.id);
        formData.append("name", name);
        formData.append("shortname", shortname);
        formData.append("note", note);
        formData.append("hidden", hidden);
        formData.append("public", public_);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined
        }).catch((error) => {
            return prepareErr(error);
        })
        if (response?.data?.status === "success") {
            return response.data.storage;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return null;
        }
    },

    delete: async function(storage_id, cancel = false) {
        const url = "/storages/delete";
        const formData = new FormData();
        formData.append("storage_id", storage_id);
        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined
        }).catch((error) => {
            return prepareErr(error);
        })
        if (response?.data?.status === "success") {
            return true;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return false;
        }
    },

    export: async function(storage_id, cancel = false) {
        const url = "/storages/export";
        const formData = new FormData();
        formData.append("storage_id", storage_id);

        const response = await api.post(url, formData, {
            signal: cancel ? cancelApiObject[this.export.name].handleRequestCancellation().signal : undefined
        }).catch((error) => {
            return prepareErr(error);
        })
        if (response?.data?.status === "success") {
            return response?.data?.content;
        } else {
            showAPIErr(url, response?.data?.message || "Unspecified error");
            return false;
        }
    }
}


// Show formatted API error
const showAPIErr = (url, message) => {
    let errMsg = "Error in storageAPI call " + url +  " : " + message;
    notifier.logToConsole(notifier.level.error, errMsg, true);
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelAPIObject(storageAPI);