import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './DropDownMenu.css'

export const dropDownTemplate = {
    small: 'small',
    small_left: 'small',
    small_right: 'small-right',
}


/**
 * Drop-down menu
 */
class DropDownMenu extends Component {
    static propTypes = {
        // Drop-down menu template (see dropDownTemplate)
        template:   PropTypes.oneOf(Object.values(dropDownTemplate)).isRequired,
    }

    constructor(props){
        super(props)

        this.state = {
            isShow: false
        }
        this.dropdownRef = React.createRef()
    }

    componentDidMount = () => {
        if(this.state.isShow)
            this._addClickListener()
    }
    componentWillUnmount = () => {
        this._removeClickListener()
    }

    render() {
        return (
            <div ref={this.dropdownRef} className={"drop-down-menu template-" + this.props.template + (this.state.isShow?" show":"")} >
                <div className="drop-down-icon" onClick={this.show}></div>
                <div className="drop-down-items" onClick={this.close}>
                    {this.props.children}
                </div>
            </div>
        )
    }

    show = (event) => {
        if(event) // Restricts click triggering to the drop-down menu
            event.stopPropagation()
        this.setState({isShow: true})
        this._addClickListener()
    }
    close = (event) => {
        if(event) // Restricts click triggering to the drop-down menu
            event.stopPropagation()
        if (!this.state.isShow)
            return
        this.setState({isShow: false})
        this._removeClickListener()
    }

    handleClickOutside = (event) => {
        if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
            this.close()
        }
    }

    _addClickListener = () => {
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    _removeClickListener = () => {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }
}

export default DropDownMenu