import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './CardPrintsList.css'
//import { scry } from '../../libs/scry'

/**
 * List of cards that represents prints of some card
 */
 class CardPrintsList extends Component{
    static propTypes = {
        // Selected card
        card: PropTypes.object.isRequired,
        // Card prints
        cards_list: PropTypes.arrayOf(PropTypes.object).isRequired,
        //
        prints_count: PropTypes.number.isRequired,
        // On select callback
        onSelect: PropTypes.func,
    }

    getSelectedClass = (scry_card) => {
        // If one of cards not set
        if (!scry_card || !this.props.card)
            return ""
        // Compare scryfall IDs
        return ((this.props.card.uuid === scry_card.id) ? " selected" : "")
    }

    render = () => {
        return (
            <div className="card-print-list">
                <div className="title"><span className="count">{this.props.prints_count}</span> variants of the card <span className="card-name">{this.props.card ? this.props.card.name : ""}</span></div>
                <div className="content">
                    {this.props.cards_list.map((scry_card, index)=>(
                        <div className={"card-print"+this.getSelectedClass(scry_card)} key={scry_card.id} onClick={() => this.handleSelect(scry_card)}>
                            <div className="collector-number"><span className="set-code">{scry_card.set}</span> <span className="c_number">{scry_card.collector_number}</span></div>
                            <div className="set-from">set <span className="set-name">{scry_card.set_name}</span></div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    handleSelect = (scry_card) => {
        if (this.props.onSelect) {
            this.props.onSelect(scry_card)
        }
    }
 }

 export default CardPrintsList