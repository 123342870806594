import { useCallback } from "react";
import { createContext, useState, useEffect } from "react";

import { mtgAPI } from "../apis/mtgAPI";
import { scryAPI } from "../apis/scryAPI";

export const MtgContext = createContext(null);

export const MtgProvider = ({children}) => {
    //const params = useParams();
    const [sets, setSets] = useState({});
    const [symbols, setSymbols] = useState({});
    const [version] = useState({ frontend: "0.2.0"})
    const [lastUpdate, setLastUpdate] = useState("");
    const [cutoffPrice, setCutoffPrice] = useState(0.5);

    useEffect(() => {
        console.log('Initialization: MtgProvider');
        // Loading sets list
        mtgAPI.getSets().then((resp_sets) => {
            //console.log(resp_sets);
            let setsUpd = {};
            for (let i = 0; i < resp_sets.length; i++) {
                setsUpd[resp_sets[i].id] = resp_sets[i];
            }
            setSets(setsUpd);
        })
        // Loading lastUpdate
        mtgAPI.getParams().then((params) => {
            if (params) {
                if (params.last_update)
                    setLastUpdate(params.last_update);
            }
        })
        // Loading symbols
        scryAPI.getSymbols().then((resp) => {
            let symbolsUpd = {};
            for (let i = 0; i < resp.length; i++) {
                const symbol = resp[i];
                symbolsUpd[symbol.symbol] = symbol;
            }
            setSymbols(symbolsUpd);
        });
    }, [])

    const getContent = useCallback((alias, onResult) => {
        mtgAPI.getContent(alias).then((resp) => {
            onResult(resp);
        })
    }, [])

    const getShortCondition = useCallback((sample) => {
        if (sample.condition in conditionShort)
            return conditionShort[sample.condition];
        return "?";
    }, [])

    const getFullCondition = useCallback((sample) => {
        if (sample.condition in conditionFull)
            return conditionFull[sample.condition];
        return "Unknown condition";
    }, [])

    const updCardRarity = useCallback((card) => {
        if (card.rarity in cardRarityTitle)
            card.rarity = cardRarityTitle[card.rarity];
        else
            card.rarity = cardRarityTitle[cardRarity.unknown];
        return card;
    }, [])

    const value = {
        version,
        lastUpdate,
        sets,
        language,
        getContent,
        getShortCondition,
        getFullCondition,
        updCardRarity,
        cutoffPrice,
        setCutoffPrice
    };

    return (
        <MtgContext.Provider value={value}>
            {children}
        </MtgContext.Provider>
    )
}

export const language = {
    'en': {short: 'Eng', full: 'English'},
    'zhs': {short: 'Zhs', full: 'Chinese Simplified'},
    'zht': {short: 'Zht', full: 'Chinese Traditional'},
    'fr': {short: 'Fre', full: 'French'},
    'de': {short: 'Ger', full: 'German'},
    'it': {short: 'Ita', full: 'Italian'},
    'ja': {short: 'Jpn', full: 'Japanese'},
    'ko': {short: 'Kor', full: 'Korean'},
    'pt': {short: 'Por', full: 'Portuguese'},
    'ru': {short: 'Rus', full: 'Russian'},
    'es': {short: 'Spa', full: 'Spanish'},
    //'la': {short: 'Lat', full: 'Latin'},
    //'grc': {short: 'Grc', full: 'Ancient Greek'},
    //'sa': {short: 'San', full: 'Sanskrit'},
    //'ar': {short: 'Ara', full: 'Arabic'},
    //'he': {short: 'He', full: 'Hebrew'},
    //'ph': {short: 'Phy', full: 'Phyrexian'},
}

const cardRarityTitle = {
    0: "unknown",
    1: "common",
    2: "uncommon",
    3: "rare",
    4: "mythic",
    5: "special",
    6: "bonus",
}

export const cardRarity = {
    unknown: 0,
    common: 1,
    uncommon: 2,
    rare: 3,
    mythic: 4,
    special: 5,
    bonus: 6,
}

export const cardFinish = {
    nonfoil: 0,
    foil: 1,
    etched: 2,
}
export const cardFinishText = {
    0: "non-foil",
    1: "foil",
    2: "etched",
}

const conditionShort = {
    0: "M",
    1: "NM",
    2: "LP",
    3: "MP",
    4: "HP",
    5: "D",
}

const conditionFull = {
    0: "Mint",
    1: "Near Mint",
    2: "Lightly Played (Excelent)",
    3: "Moderately Played (Good)",
    4: "Heavily Played (Played)",
    5: "Damaged (Poor)",
}