import React from 'react'
import PropTypes from 'prop-types'

import './SimpleSelect.css'



/**
 * This simple component represents a control that provides a menu of options
 * @component
 */
export const SimpleSelect = (props) => {
    const cls = "simple-select"+(props.className ? " " + props.className : "")
    return(
        <select
            value={props.value}
            className={cls}
            title={props.title}
            onChange={props.onChange}
            tabIndex={props.tabIndex}
        >
            {props.children}
        </select>
    )
}

SimpleSelect.propTypes = {
    // Selected value
    value:      PropTypes.string.isRequired,
    // Title html attribute for component
    title:      PropTypes.string,
    // Additional class
    className:  PropTypes.string,
    // Callback function on change
    onChange:   PropTypes.func.isRequired
}



/**
 * This simple component represents a control that provides a menu of options prepended by label
 * @component
 */
export const SimpleSelectLabeled = (props) => {
    const cls = "simple-select-labeled" + (props.className ? " " + props.className : "")
    return(
        <div className={cls}>
            <span className="label">{props.label}</span>
            <SimpleSelect
                value={props.value}
                className={props.className}
                title={props.title}
                onChange={props.onChange}
            >
                {props.children}
            </SimpleSelect>
        </div>
    )
}

SimpleSelectLabeled.propTypes = {
    // Selected value
    value:      PropTypes.string.isRequired,
    // Label text
    label:      PropTypes.string.isRequired,
    // Title html attribute for component
    title:      PropTypes.string,
    // Additional class
    className:  PropTypes.string,
    // Callback function on change
    onChange:   PropTypes.func,
}

export default SimpleSelect