import React from 'react'
import PropTypes from 'prop-types'

import './WorkspacePanel.css'



/**
 * Workspace panel
 * @component
 */
export const WorkspacePanel = (props) => {
    return (
        <div className={"panel" + (props.className ? " " + props.className : "")}>
            {props.children}
        </div>
    )
}

WorkspacePanel.propTypes = {
    // Additional class for panel
    className:  PropTypes.string,
}



/**
 * Head for workspace panel
 * @component
 */
export const WorkspacePanelHead = (props) => {
    return (
        <div className={"head" + (props.className ? " " + props.className : "")}>
            <div className="title">{props.title}</div>
            <div className="body">
                {props.children}
            </div>
        </div>
    )
}

WorkspacePanelHead.propTypes = {
    // Title for panel head
    title:      PropTypes.string.isRequired,
    // Additional class
    className:  PropTypes.string,
}

export default WorkspacePanel