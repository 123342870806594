import PropTypes from 'prop-types'

import { wrap } from '../../layouts/layouts'

export const DisconectedPage = (props) => {
    return wrap("raw", props, (
        <div className="centered-block">
            <div className="text"><strong>Couldn't connect to server</strong></div>
        </div>
    ))
}

DisconectedPage.propTypes = {
    // Page title for the browser tab
    title:  PropTypes.string.isRequired,
}


export default DisconectedPage