import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './StorageSamplesPanel.css'

import { useStorage } from '../../../hook/useStorage'
import { useSample } from '../../../hook/useSample'
import { CardProvider } from '../../../hoc/CardProvider'
import WorkspacePanel, {WorkspacePanelHead} from '../../../components/WorkspacePanel/WorkspacePanel'
import StorageInfo from '../../../components/StorageInfo/StorageInfo'
import SamplesList from '../../../components/SamplesList/SamplesList'
import { sortType } from '../../../components/SamplesList/SampleListContent'
import AddSampleModal from '../../../components/Modals/AddSampleModal/AddSampleModal'
import ConfirmModal from '../../../components/Modals/ConfirmModal/ConfirmModal'


/**
 * List of samples for active storage
 * @component
 */
const StorageSamplesPanel = ({onActivateSample, ...props}) => {
    const {activeStorage} = useStorage();
    const {samples, activeSample, updatedSample, deleteSample} = useSample();

    // collection information state
    const [collectionInfo, setCollectionInfo] = useState(true);

    const [isDeleteteModalOpen, setDeleteModalOpen] = useState(false);
    const [isAddModalOpen, setAddModalOpen] = useState(false);

    useEffect(() => {
        // console.log('reset keydown listener')
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'Insert':
                    if (props.isOwned)
                        handleShowAddSample();
                    return;
                case 'Escape':
                    onActivateSample(null);
                    return;
                case 'Delete':
                    if (activeSample && props.isOwned)
                        setDeleteModalOpen(true);
                    return;
                default:
                    return;
            }
        }

        document.addEventListener("keydown", handleKeyDown, false)
        return () => {
            document.removeEventListener("keydown", handleKeyDown, false)
        }
    }, [onActivateSample, activeSample, props.isOwned])


    return (
        <WorkspacePanel className="storage-samples">
            <WorkspacePanelHead
                title={activeStorage.name}
            >
                <span className="action" onClick={toggleInfo}>({collectionInfo ? "hide info" : "show info"})</span>
                <div className="spacer"></div>
                {props.isOwned && (
                <div className="mtgbutton button-add"
                    title="Add a sample to this storage (Ins)"
                    onClick={handleShowAddSample}
                >Add Sample</div>
                )}
            </WorkspacePanelHead>

            {collectionInfo && (
            <StorageInfo
                storage={activeStorage}
            />
            )}

            <SamplesList
                samples={samples}
                is_owned={props.isOwned}
                active_sample={activeSample}
                updated_sample={updatedSample}
                show_storage={false}
                default_sort={sortType.collectorNumber}
                onActivate={onActivateSample}
            />

            <CardProvider>
            <AddSampleModal
                isOpen={isAddModalOpen}
                storage={activeStorage}
                onAddSample={handleAddSample}
                onRequestClose={handleCloseAddSample}
            />
            </CardProvider>

            <ConfirmModal
                isOpen={isDeleteteModalOpen}
                title="Delete sample"
                text="Are you sure you want to delete this sample?"
                defaultYes={true}
                onResult={handleDeleteSample}
            />
        </WorkspacePanel>
    )


    function toggleInfo() {
        setCollectionInfo(!collectionInfo);
    }

    function handleShowAddSample() {
        setAddModalOpen(true);
    }

    function handleCloseAddSample() {
        setAddModalOpen(false);
    }

    function handleAddSample(sample) {
        props.onUpdateSample(sample);
        setAddModalOpen(false);
    }

    function handleDeleteSample(result) {
        if (result) {
            if (activeSample) {
                deleteSample(activeSample.id, (status) => {
                    onActivateSample(null);
                    props.onUpdateSample(null);
                })
            }
        }
        setDeleteModalOpen(false);
    }
}

StorageSamplesPanel.propTypes = {
    // Is this storage owned by current user
    isOwned:            PropTypes.bool.isRequired,
    // Callback function on sample select
    onActivateSample:   PropTypes.func.isRequired,
    // Callback function after sample updated
    onUpdateSample:     PropTypes.func.isRequired,
}

export default StorageSamplesPanel