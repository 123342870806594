import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './SampleInfo.css'

import { useMtg } from '../../hook/useMtg'
import { useSample } from '../../hook/useSample'
import { cardFinishText } from '../../hoc/MtgProvider'
import ConfirmModal from '../Modals/ConfirmModal/ConfirmModal'
import MoveSamplesModal from '../Modals/MoveSamplesModal/MoveSamplesModal'


/**
 * Represent sample information
 * @component
 */
const SampleInfo = (props) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);

    const {deleteSample} = useSample();
    const {getShortCondition, getFullCondition} = useMtg();

    const [samplesObj, setSamplesObj] = useState({});

    useEffect(() => {
        console.log('Initialization: SampleInfo')
    }, [])

    useEffect(() => {
        let samplesObjUpd = {};
        samplesObjUpd[props.sample.id] = props.sample;
        setSamplesObj(samplesObjUpd);

    }, [props.sample])

    return (
        <div className="sample-info">
            <div className="quantity">
                <span className="qty">{props.sample.qty}</span>&thinsp;×&thinsp;
                <span className={"finish finish-"+props.sample.finish}>{props.sample.finish in cardFinishText ? cardFinishText[props.sample.finish] : "?"}</span>&nbsp;
                <span className="language">({props.sample.language})</span>
            </div>
            <div className="condition" title="Card condition">
                <div className="content">
                    <span className={"state "+getShortCondition(props.sample)}>{getShortCondition(props.sample)}</span>
                    <span className="full">{getFullCondition(props.sample)}</span>
                </div>
            </div>
            {props.sample.note && (
            <div className="note">Note: {props.sample.note}</div>
            )}
            {props.is_owned && (
            <div className="button-row">
                <div className="mtgbutton" onClick={handleEdit}>Change</div>
                <div className="mtgbutton" onClick={openMoveDialog}>Move</div>
                <div className="mtgbutton red right" onClick={openDeleteConfirmDialog}>Delete</div>
            </div>
            )}

            <ConfirmModal
                isOpen={showDeleteModal}
                title="Delete sample"
                text="Are you sure you want to delete this sample?"
                defaultYes={true}
                onResult={handleDeleteSample}
            />

            <MoveSamplesModal
                isOpen={showMoveModal}
                samples={samplesObj}
                onConfirm={props.onMove}
                onCancel={handleCloseMoveDialog}
            />
        </div>
    )

    function handleEdit() {
        if (props.onStartEdit)
            props.onStartEdit();
    }
    function openMoveDialog() {
        setShowMoveModal(true);
    }
    function handleCloseMoveDialog() {
        setShowMoveModal(false);
    }
    function openDeleteConfirmDialog() {
        setShowDeleteModal(true);
    }
    function handleDeleteSample(result) {
        if (result) {
            if (props.sample) {
                deleteSample(props.sample.id, (status) => {
                    props.onDelete?.(status);
                })
            }
        }
        setShowDeleteModal(false);
    }
}

SampleInfo.propTypes = {
    // Sample object
    sample:         PropTypes.object.isRequired,
    // Is this sample owned by current user
    is_owned:       PropTypes.bool.isRequired,
    // Callback function on edit request
    onStartEdit:    PropTypes.func.isRequired,
    // Callback function after deletion
    onDelete:       PropTypes.func.isRequired,
    // Callback function after move
    onMove:         PropTypes.func.isRequired,
}

export default SampleInfo