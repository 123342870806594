import { createContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { sampleAPI } from "../apis/sampleAPI";
import { useMtg } from "../hook/useMtg";
import { useStorage } from "../hook/useStorage";

export const SampleContext = createContext(null);

export const SampleProvider = ({children}) => {
    const params = useParams();

    const [activeSample, setActiveSample] = useState(null);
    const [updatedSample, setUpdatedSample] = useState(null);
    const [samples, setSamples] = useState({});
    const [loading, setLoading] = useState(true);

    const {
        activeStorage,
        refreshStorages,
    } = useStorage();

    const {
        sets,
        updCardRarity,
    } = useMtg();

    useEffect(() => {
        console.log('Initialization: SampleProvider');
    }, [])

    useEffect(() => {
        if (activeStorage === null) { // Deselected storage
            setActiveSample(null); // Disable active storage
            setSamples({}); // Empty samples list
            setLoading(false); // Reset loading status
        } else {
            setLoading(true);
            sampleAPI.get(activeStorage.id).then((resp) => {
                // If response without cards/samples
                if (!("cards" in resp) || !("samples" in resp)) {
                    setSamples({});
                    setLoading(false);
                    return;
                }

                let cards_by_id = {};
                for (let card of resp.cards) {
                    cards_by_id[card.id] = updCardRarity(card);
                }

                // Apply samples to cache
                let samplesUpd = {}
                for (let i = 0; i < resp.samples.length; i++) {
                    let sample_ext = resp.samples[i];
                    sample_ext.card = sample_ext.card_id in cards_by_id ? cards_by_id[sample_ext.card_id] : null;
                    sample_ext.set = sample_ext.card && sample_ext.card.set_id ? sets[sample_ext.card.set_id] : null;
                    samplesUpd[resp.samples[i].id] = sample_ext;
                }
                setSamples(samplesUpd);
                setLoading(false);
                //console.log('samplesUpd:', samplesUpd)
            })
        }
    }, [activeStorage, sets, updCardRarity])

    useEffect(() => {
        let sampleUpd = null;
        if (params.sample_id in samples) {
            sampleUpd = samples[params.sample_id];
        }
        setActiveSample(sampleUpd);
    }, [samples, params.sample_id])

    function selectSample(sample) {
        setActiveSample(sample);
    }

    function addSample(sample_id, storage_id, qty, condition, finish, note, language, onResult) {
        sampleAPI.add(sample_id, storage_id, qty, condition, finish, note, language).then((resp) => {
            if (resp) {
                let sampleUpd = resp.sample;
                sampleUpd.card = updCardRarity(resp.card);
                sampleUpd.set = resp.card.set_id in sets ? sets[resp.card.set_id] : null;

                let samplesUpd = {...samples};
                samplesUpd[sampleUpd.id] = sampleUpd;
                setSamples(samplesUpd);
                setUpdatedSample(sampleUpd);

                refreshStorages([resp.storage]);

                onResult(sampleUpd);
            } else {
                onResult(null);
            }
        })
    }

    function updateSample(sample_id, storage_id, qty, condition, finish, note, language, onResult) {
        sampleAPI.update(sample_id, storage_id, qty, condition, finish, note, language).then((resp) => {
            if (resp) {
                let sampleUpd = resp.sample;
                sampleUpd.card = updCardRarity(resp.card);
                sampleUpd.set = resp.card.set_id in sets ? sets[resp.card.set_id] : null;

                let samplesUpd = {...samples};
                delete samplesUpd[sampleUpd.id];
                samplesUpd[sampleUpd.id] = sampleUpd;
                setSamples(samplesUpd);
                setUpdatedSample(sampleUpd);

                if (activeSample?.id === sampleUpd.id) {
                    setActiveSample(sampleUpd);
                }

                refreshStorages([resp.storage]);

                onResult(sampleUpd);
            } else {
                onResult(null);
            }
        })
    }

    function moveSample(sampleIds, targetId, onResult) {
        sampleAPI.move(sampleIds, targetId).then((resp) => {
            if (resp) {
                let samplesUpd = {...samples};
                console.log('samplesUpd', samplesUpd);
                // Delete samples
                for (let i = 0; i < resp.samplesDel.length; i++) {
                    delete samplesUpd[resp.samplesDel[i].id];
                }
                // Update samples
                for (let i = 0; i< resp.samplesUpd.length; i++) {
                    try {
                    // Preserve card & set from sample
                    resp.samplesUpd[i].card = samplesUpd[resp.samplesUpd[i].id].card;
                    resp.samplesUpd[i].set = samplesUpd[resp.samplesUpd[i].id].set;
                    // Delete old and replace with new one
                    delete samplesUpd[resp.samplesUpd[i].id];
                    samplesUpd[resp.samplesUpd[i].id] = resp.samplesUpd[i];
                    }
                    catch(e) {
console.log('Error moving sample', e);
console.log('Current sample:', resp.samplesUpd[i]);
                    }
                }
                setSamples(samplesUpd);
                refreshStorages(resp.storages);
                onResult(true);
            } else {
                onResult(false);
            }
        })
    }

    function deleteSample(sampleId, onResult) {
        sampleAPI.delete(sampleId).then((resp) => {
            if (resp) {
                let samplesUpd = {...samples}
                delete samplesUpd[sampleId];
                setSamples(samplesUpd);
                if (activeSample?.id === sampleId) {
                    setActiveSample(null);
                }

                refreshStorages([resp.storage]);

                onResult(true);
            } else {
                onResult(false);
            }
        })
    }

    function getSamplesForCard(cardId, onResult) {
        sampleAPI.getSamplesForCard(cardId).then((resp) => {
            if (resp) {
                let cards = {};
                for (let card of resp.cards) {
                    cards[card.id] = updCardRarity(card);
                }
                // Apply extended samples to cache
                let samplesUpd = {};
                for (let i = 0; i < resp.samples.length; i++) {
                    let sample = resp.samples[i];
                    sample.card = sample.card_id in cards ? cards[sample.card_id] : null;
                    sample.set = sample.card && sample.card.set_id in sets ? sets[sample.card.set_id] : null;
                    samplesUpd[sample.id] = sample;
                }
                setSamples(samplesUpd);
                onResult(samplesUpd);
            } else {
                setSamples({});
                onResult({});
            }
        })
    }


    const value = {
        activeSample,
        updatedSample,
        samples,
        selectSample,
        addSample,
        updateSample,
        moveSample,
        deleteSample,
        getSamplesForCard,
        loadingSample: loading,
    };

    return (
        <SampleContext.Provider value={value}>
            {children}
        </SampleContext.Provider>
    )
}


